import React from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import EditDatasetView from "./EditDataset/EditDatasetView";
import LineChartsView from "./LineChartsView";
import XAxisView from "./XAxisView";
import YAxisView from "./YAxisView";
import ActualValuesView from "./ActualValuesView";
import { useElementSize } from "usehooks-ts";

export default function ChartView(props: any) {
  const [squareRef, { width }] = useElementSize();

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <Grid container spacing={1} justifyContent="center">
        <Grid
          item
          md={0}
          lg={1}
          xl={1}
          sx={{ display: { xs: "none", md: "none", lg: "block" } }}
        >
          <YAxisView />
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          lg={9}
          xl={9}
          ref={squareRef}
          style={{ paddingLeft: "0px" }}
        >
          <LineChartsView sizeRef={width} />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "0px 0px 10px 25px",
            }}
          >
            <XAxisView />
          </div>
        </Grid>
        <Grid item xs={11} md={8} lg={2} xl={2}>
          <ActualValuesView />
        </Grid>
      </Grid>
      <EditDatasetView context={props.context} webWorker={props.webWorker} />
    </Paper>
  );
}
