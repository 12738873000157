import React from "react";
import LineChartsLogic from "./LineChartsLogic";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";

export default function LineChartsView(props: any) {
  const {
    graphData,
    chartWidth,
    CustomTooltip,
    disabledLineGraphs,
    vh,
    xmin,
    xmax,
    ymin,
    ymax,
    generateXticks,
    generateYticks,
  } = LineChartsLogic(props);

  return (
    <LineChart width={chartWidth} height={vh * 0.78}>
      <Tooltip filterNull={true} content={<CustomTooltip />} />

      {graphData.map((s: any) => {
        if (
          disabledLineGraphs.find(
            (graphs: any) =>
              graphs.controller === s.controller && graphs.adr === s.adr
          )
        ) {
          return "";
        } else {
          return (
            <Line
              isAnimationActive={false}
              activeDot={{ r: 8 }}
              type="stepAfter"
              animationDuration={200}
              strokeWidth={2}
              animationEasing="ease-out"
              dot={false}
              connectNulls
              dataKey="value"
              data={s.data}
              name={s.name}
              key={s.controller + s.name}
              stroke={s.color}
            />
          );
        }
      })}

      <CartesianGrid strokeDasharray="3 3" />
      <XAxis
        allowDuplicatedCategory={false}
        allowDataOverflow={true}
        ticks={generateXticks()}
        domain={[xmin, xmax]}
        dataKey="time"
        type="number"
        tickFormatter={(unixTime) => new Date(unixTime).getHours() + ":" + new Date(unixTime).getMinutes()}
      />
      <YAxis
        allowDataOverflow={true}
        ticks={generateYticks()}
        domain={[ymin, ymax]}
        dataKey="value"
        type="number"
      />
    </LineChart>
  );
}
