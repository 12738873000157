import { createSlice } from "@reduxjs/toolkit";
import { HPData } from "../../../types";

interface HP {
  heatpumps: any;
  filteredHeatpumps: any;
  heatpumpsFilterText: string;
  lastUpdate: string;
  activeAlarms: any;
  resolvedAlarms: any;
  vpnIP: string;
  masterSettings: [];
  modul1Settings: [];
  modul2Settings: [];
  modul3Settings: [];
  modul4Settings: [];
  masterHotspots: [];
  modul1Hotspots: [];
  modul2Hotspots: [];
  modul3Hotspots: [];
  modul4Hotspots: [];
  graphParamList: {};
  chartDataset: {
    MS: [];
    MH: [];
    S1S: [];
    S1H: [];
    S2S: [];
    S2H: [];
    S3S: [];
    S3H: [];
    S4S: [];
    S4H: [];
  };
  liveDataset: [];
  graphData: [];
  graphDate: string;
  disabledLineGraphs: [];
  logs: [];
  HPData: HPData;
  updateGWState: string;
  NavOpenHotspots: boolean;
  NavOpenSettings: boolean;
  liveDataTopic: string;
  handlerTopic: string;
  gwDataTopic: string;
  newSoftwareTopic: string;
  changeSettingsState: { DataMessage: string; DataState: number };
  CognitoUsername: string;
  mcxFileUploadProgress: number;
  mcxFileOnGateway: boolean;
  mcxGWRestarted: boolean;
  chooseController: { 1: boolean; 2: boolean; 100: boolean };
  controllerIsSet: boolean;
  fileToUpload: string;
  fileChecksum: number;
  fileChunks: [];
  xmin: number;
  xDuration: number;
  xmax: number;
  xsteps: number;
  xnextUpdate: number;
  ymin: number;
  ymax: number;
  ysteps: number;
  actualValues: any;
  liveData: boolean;
  openEditDatasetModal: boolean;
  historicDataDateList: [];
  mainView: string;
  selectedMenu: string;
  headerTitle: string;
}

const initialState: HP = {
  heatpumps: [],
  filteredHeatpumps: [],
  heatpumpsFilterText: "",
  lastUpdate: "",
  vpnIP: "",
  activeAlarms: [],
  resolvedAlarms: [],
  masterSettings: [],
  modul1Settings: [],
  modul2Settings: [],
  modul3Settings: [],
  modul4Settings: [],
  masterHotspots: [],
  modul1Hotspots: [],
  modul2Hotspots: [],
  modul3Hotspots: [],
  modul4Hotspots: [],
  graphParamList: {},
  chartDataset: {
    MS: [],
    MH: [],
    S1S: [],
    S1H: [],
    S2S: [],
    S2H: [],
    S3S: [],
    S3H: [],
    S4S: [],
    S4H: [],
  },
  liveDataset: [],
  graphData: [],
  graphDate: "",
  disabledLineGraphs: [],
  logs: [],
  HPData: {} as HPData,
  updateGWState: "nothing",
  NavOpenHotspots: false,
  NavOpenSettings: false,
  liveDataTopic: "",
  handlerTopic: "",
  gwDataTopic: "",
  newSoftwareTopic: "",
  changeSettingsState: { DataMessage: "", DataState: 0 },
  CognitoUsername: "",
  mcxFileUploadProgress: 0,
  mcxFileOnGateway: false,
  mcxGWRestarted: false,
  chooseController: { 1: false, 2: false, 100: false },
  controllerIsSet: false,
  fileToUpload: "",
  fileChecksum: 0,
  fileChunks: [],
  xmin: Date.now() - 120000,
  xDuration: 900000,
  xmax: Date.now() + 660000,
  xsteps: 120000,
  xnextUpdate: Date.now() + 720000,
  ymin: -20,
  ymax: 110,
  ysteps: 5,
  actualValues: {},
  liveData: true,
  openEditDatasetModal: false,
  historicDataDateList: [],
  mainView: "",
  selectedMenu: "",
  headerTitle: "",
};
export const HPslice = createSlice({
  name: "heatpump",
  initialState,
  reducers: {
    setHeatpumps: (state, action) => {
      state.heatpumps = action.payload;
    },
    setFilteredHeatpumps: (state, action) => {
      state.filteredHeatpumps = action.payload;
    },
    setHeatpumpsFilterText: (state, action) => {
      state.heatpumpsFilterText = action.payload;
    },
    setActiveAlarms: (state, action) => {
      state.activeAlarms = action.payload;
    },
    removeActiveAlarm: (state, action) => {
      state.activeAlarms = state.activeAlarms.filter(
        (alarm) => alarm.id !== action.payload
      );
    },
    removeResolvedAlarm: (state, action) => {
      state.resolvedAlarms = state.resolvedAlarms.filter(
        (alarm) => alarm.id !== action.payload
      );
    },
    resetAlarms: (state) => {
      state.activeAlarms = [];
      state.resolvedAlarms = [];
    },
    setResolvedAlarms: (state, action) => {
      state.resolvedAlarms = action.payload;
    },

    setLastUpdate: (state, action) => {
      state.lastUpdate = action.payload;
    },
    emptyLastUpdate: (state) => {
      state.lastUpdate = "";
    },
    setvpnIP: (state, action) => {
      state.vpnIP = action.payload;
    },
    emptyvpnIP: (state) => {
      state.vpnIP = "";
    },
    setMasterSettings: (state, action) => {
      state.masterSettings = action.payload;
    },
    setModul1Settings: (state, action) => {
      state.modul1Settings = action.payload;
    },
    setModul2Settings: (state, action) => {
      state.modul2Settings = action.payload;
    },
    setModul3Settings: (state, action) => {
      state.modul3Settings = action.payload;
    },
    setModul4Settings: (state, action) => {
      state.modul4Settings = action.payload;
    },
    setMasterHotspots: (state, action) => {
      state.masterHotspots = action.payload;
    },
    setModul1Hotspots: (state, action) => {
      state.modul1Hotspots = action.payload;
    },
    setModul2Hotspots: (state, action) => {
      state.modul2Hotspots = action.payload;
    },
    setModul3Hotspots: (state, action) => {
      state.modul3Hotspots = action.payload;
    },
    setModul4Hotspots: (state, action) => {
      state.modul4Hotspots = action.payload;
    },
    setGraphParamList: (state, action) => {
      state.graphParamList = action.payload;
    },
    setChartDataset: (state, action) => {
      state.chartDataset = action.payload;
    },
    setLiveDataset: (state, action) => {
      state.liveDataset = action.payload;
    },
    setGraphData: (state, action) => {
      state.graphData = action.payload;
    },
    setGraphDate: (state, action) => {
      state.graphDate = action.payload;
    },
    setDisabledLineGraphs: (state, action) => {
      state.disabledLineGraphs = action.payload;
    },
    setLogs: (state, action) => {
      state.logs = action.payload;
    },
    setHPData: (state, action) => {
      state.HPData = action.payload;
    },
    setUpdateGWState: (state, action) => {
      state.updateGWState = action.payload;
    },
    setNavOpenHotspots: (state, action) => {
      state.NavOpenHotspots = action.payload;
    },
    setNavOpenSettings: (state, action) => {
      state.NavOpenSettings = action.payload;
    },
    setLiveDataTopic: (state, action) => {
      state.liveDataTopic = action.payload;
    },
    setHandlerTopic: (state, action) => {
      state.handlerTopic = action.payload;
    },
    setGwDataTopic: (state, action) => {
      state.gwDataTopic = action.payload;
    },
    setNewSoftwareTopic: (state, action) => {
      state.newSoftwareTopic = action.payload;
    },
    setChangeSettingsState: (state, action) => {
      state.changeSettingsState = action.payload;
    },
    setCognitoUsername: (state, action) => {
      state.CognitoUsername = action.payload;
    },
    setMcxFileUploadProgress: (state, action) => {
      state.mcxFileUploadProgress = action.payload;
    },
    setMcxFileOnGateway: (state, action) => {
      state.mcxFileOnGateway = action.payload;
    },
    setMcxGWRestarted: (state, action) => {
      state.mcxGWRestarted = action.payload;
    },
    setFileToUpload: (state, action) => {
      state.fileToUpload = action.payload;
    },
    setFileChecksum: (state, action) => {
      state.fileChecksum = action.payload;
    },
    setFileChunks: (state, action) => {
      state.fileChunks = action.payload;
    },
    setChooseController: (state, action) => {
      state.chooseController = action.payload;
    },
    setControllerIsSet: (state, action) => {
      state.controllerIsSet = action.payload;
    },
    setXmin: (state, action) => {
      state.xmin = action.payload;
    },
    setXDuration: (state, action) => {
      state.xDuration = action.payload;
    },
    setXmax: (state, action) => {
      state.xmax = action.payload;
    },
    setXsteps: (state, action) => {
      state.xsteps = action.payload;
    },
    setXnextUpdate: (state, action) => {
      state.xnextUpdate = action.payload;
    },
    setYmin: (state, action) => {
      state.ymin = action.payload;
    },
    setYmax: (state, action) => {
      state.ymax = action.payload;
    },
    setYsteps: (state, action) => {
      state.ysteps = action.payload;
    },
    setActualValues: (state, action) => {
      state.actualValues = action.payload;
    },
    setLiveData: (state, action) => {
      state.liveData = action.payload;
    },
    setOpenEditDatasetModal: (state, action) => {
      state.openEditDatasetModal = action.payload;
    },
    changeActualValues: (state, action) => {
      var name = action.payload.name;
      var value = action.payload.value;
      state.actualValues[name] = value;
    },
    setHistoricDataDateList: (state, action) => {
      state.historicDataDateList = action.payload;
    },
    setHeaderTitle: (state, action) => {
      state.headerTitle = action.payload;
    },
    setSelectedMenu: (state, action) => {
      state.selectedMenu = action.payload;
    },
    setMainView: (state, action) => {
      state.mainView = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setHeatpumps,
  setFilteredHeatpumps,
  setHeatpumpsFilterText,
  setLastUpdate,
  emptyLastUpdate,
  setvpnIP,
  emptyvpnIP,
  setActiveAlarms,
  removeActiveAlarm,
  removeResolvedAlarm,
  resetAlarms,
  setResolvedAlarms,
  setMasterSettings,
  setModul1Settings,
  setModul2Settings,
  setModul3Settings,
  setModul4Settings,
  setMasterHotspots,
  setModul1Hotspots,
  setModul2Hotspots,
  setModul3Hotspots,
  setModul4Hotspots,
  setGraphParamList,
  setChartDataset,
  setLiveDataset,
  setGraphData,
  setGraphDate,
  setDisabledLineGraphs,
  setLogs,
  setHPData,
  setUpdateGWState,
  setNavOpenHotspots,
  setNavOpenSettings,
  setLiveDataTopic,
  setHandlerTopic,
  setGwDataTopic,
  setNewSoftwareTopic,
  setChangeSettingsState,
  setCognitoUsername,
  setMcxFileUploadProgress,
  setMcxFileOnGateway,
  setMcxGWRestarted,
  setFileToUpload,
  setFileChecksum,
  setFileChunks,
  setChooseController,
  setControllerIsSet,
  setXmin,
  setXDuration,
  setXmax,
  setXsteps,
  setXnextUpdate,
  setYmin,
  setYmax,
  setYsteps,
  setActualValues,
  setLiveData,
  setOpenEditDatasetModal,
  changeActualValues,
  setHistoricDataDateList,
  setHeaderTitle,
  setSelectedMenu,
  setMainView,
} = HPslice.actions;

export default HPslice.reducer;
