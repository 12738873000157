import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate, useSearchParams } from "react-router-dom";
import { API } from "aws-amplify";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import useSWR from "swr";
import CircularProgress from "@mui/material/CircularProgress";
import { useHeatpumpData } from "../../util/authorizer/useHeatpumpData";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import HeatPump from "@mui/icons-material/HeatPump";

const fetcher = (id) => {
  const apiName = "ThermonovaAPI";
  const path = "/organization/list";
  return API.get(apiName, path, {
    queryStringParameters: {
      id,
    },
  });
};

type Inputs = {
  hpIds: {
    [key: string]: boolean;
  };
};

export default function AssignHP() {
  const [busy, setBusy] = React.useState(false);
  const [error, setError] = React.useState("");
  const [success, setSuccess] = React.useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { handleSubmit, control } = useForm<Inputs>({
    defaultValues: {
      hpIds: {},
    },
  });

  const { data: heatpumps, searchQuery, setSearchQuery } = useHeatpumpData();

  const handleClose = () => {
    navigate("/access-management/company");
  };

  const organizationId = searchParams.get("organization");

  const {
    data,
    error: errorFetchingOrganization,
    isLoading,
  } = useSWR(organizationId, fetcher);
  const organization = data?.organization;

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      const apiName = "ThermonovaAPI";
      const path = "/organization/assign-hp";
      setBusy(true);
      await API.post(apiName, path, {
        body: {
          organizationId,
          heatpumpIds: Object.keys(data.hpIds).filter((key) => data.hpIds[key]),
        },
      });

      handleClose();
    } catch (error) {
      const errorText = error.message || error;
      setError(errorText);
    } finally {
      setBusy(false);
    }
  };

  return (
    <>
      <Dialog
        open
        onClose={handleClose}
        style={{
          // TODO: Not sure if this is the best way to set the height
          height: "80vh",
        }}
        PaperProps={{
          component: "form",
          onSubmit: handleSubmit(onSubmit),
        }}
      >
        <DialogTitle>Assign Heatpump to Organization</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select the heat pump you want to assign to{" "}
            <b>{organization?.name}</b>.
          </DialogContentText>

          <SearchSection
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            {heatpumps?.map((hp) => {
              const labelId = `checkbox-list-label-${hp.sk}`;

              return (
                <ListItem
                  key={hp.sk}
                  secondaryAction={
                    <IconButton edge="end" aria-label="comments">
                      <HeatPump />
                    </IconButton>
                  }
                  disablePadding
                >
                  <Controller
                    name={`hpIds.${hp.sk}`}
                    control={control}
                    render={({ field }) => (
                      <>
                        <ListItemButton
                          role={undefined}
                          onClick={() => {
                            field.onChange({
                              target: {
                                value: !Boolean(field.value),
                              },
                            });
                          }}
                          dense
                        >
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ "aria-labelledby": labelId }}
                              checked={Boolean(field.value)}
                              {...field}
                            />
                          </ListItemIcon>
                          <ListItemText
                            id={labelId}
                            primary={
                              <span>
                                {hp.Name} - {hp.Serialnumber}
                              </span>
                            }
                          />
                        </ListItemButton>
                      </>
                    )}
                  />
                </ListItem>
              );
            })}
          </List>
          {errorFetchingOrganization && (
            <Alert severity="error">{errorFetchingOrganization}</Alert>
          )}
          {isLoading ? <CircularProgress /> : <div></div>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" disabled={busy}>
            Assign
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={success}
        autoHideDuration={4000}
        onClose={() => {
          setSuccess(false);
        }}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Heat pumps assigned successfully
        </Alert>
      </Snackbar>
      <Snackbar
        open={error !== ""}
        autoHideDuration={4000}
        onClose={() => {
          setError("");
        }}
      >
        <Alert
          onClose={handleClose}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>
    </>
  );
}

type SearchSectionProps = {
  searchQuery: string;
  setSearchQuery: (searchQuery: string) => void;
};

function SearchSection(props: SearchSectionProps) {
  const { searchQuery, setSearchQuery } = props;

  return (
    <TextField
      label="Search"
      variant="outlined"
      fullWidth
      sx={{ mb: 2, bt: 2 }}
      placeholder="Search with name or serial number"
      value={searchQuery}
      onChange={(e) => setSearchQuery(e.target.value)}
    />
  );
}
