import React from "react";
import NavButtonLogic from "./NavButtonLogic";

import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SettingsIcon from "@mui/icons-material/Settings";
import Divider from "@mui/material/Divider";
import AdjustIcon from "@mui/icons-material/Adjust";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ListItemButton from "@mui/material/ListItemButton";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import TimelineIcon from "@mui/icons-material/Timeline";
import { useAuthorizer } from "../../util/authorizer";

export default function NavButtonView(props: any) {
  const {
    settingList,
    HotspotList,
    setNavOpenHotspots,
    setNavOpenSettings,
    NavOpenSettings,
    NavOpenHotspots,
    dispatch,
  } = NavButtonLogic();
  const auth = useAuthorizer();
  return (
    <div>
      <ListItem
        dense
        selected={props.selectedMenu === "Overview"}
        onClick={() => {
          props.navClickHandler("Overview");
        }}
        style={{ padding: "4px 0px 4px 0px" }}
      >
        <ListItemButton>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Overview" />
        </ListItemButton>
      </ListItem>
      <Divider />
      <ListItemButton
        dense
        onClick={() => dispatch(setNavOpenSettings(!NavOpenSettings))}
      >
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary="Settings" />
        {NavOpenSettings ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={NavOpenSettings} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {settingList.map((text, index) => (
            <ListItem
              dense
              key={text}
              sx={{ pl: 4 }}
              selected={props.selectedMenu === "S_" + text}
              onClick={() => {
                props.navClickHandler("S_" + text);
              }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Collapse>
      <Divider />
      <ListItemButton
        dense
        onClick={() => dispatch(setNavOpenHotspots(!NavOpenHotspots))}
      >
        <ListItemIcon>
          <AdjustIcon />
        </ListItemIcon>
        <ListItemText primary="Hotpots" />
        {NavOpenHotspots ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={NavOpenHotspots} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {HotspotList.map((text, index) => (
            <ListItem
              dense
              button
              key={text}
              sx={{ pl: 4 }}
              selected={props.selectedMenu === "H_" + text}
              onClick={() => {
                props.navClickHandler("H_" + text);
              }}
            >
              <ListItemIcon>
                <AdjustIcon />
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
      </Collapse>
      <Divider />
      <ListItem
        dense
        selected={props.selectedMenu === "Graph"}
        onClick={() => {
          props.navClickHandler("Graph");
        }}
        style={{ padding: "4px 0px 4px 0px" }}
      >
        <ListItemButton>
          <ListItemIcon>
            <TimelineIcon />
          </ListItemIcon>
          <ListItemText primary="Graph" />
        </ListItemButton>
      </ListItem>
      <Divider />
      {auth.inOrgPermissions.hasHPUpdatePermissions ? (
        <ListItem
          dense
          selected={props.selectedMenu === "Upload Software"}
          onClick={() => {
            props.navClickHandler("Upload Software");
          }}
          style={{ padding: "4px 0px 4px 0px" }}
        >
          <ListItemButton>
            <ListItemIcon>
              <FileUploadIcon />
            </ListItemIcon>
            <ListItemText primary="Upload Software" />
          </ListItemButton>
        </ListItem>
      ) : (
        <></>
      )}
    </div>
  );
}
