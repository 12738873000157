import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import SaveIcon from "@mui/icons-material/Save";
import Tooltip from "@mui/material/Tooltip";
import LogInputLogic from "./LogInputLogic";

export default function MultilineTextField(props: any) {
  const { handleputLog, newLogValue, handleChange } = LogInputLogic();

  return (
    <Card
      sx={{ minWidth: 275 }}
      key="input"
      variant="outlined"
      style={{ margin: "5px" }}
    >
      <CardContent>
        <Box component="form" noValidate autoComplete="off">
          <div>
            <TextField
              id="standard-multiline-static"
              label="New Log"
              multiline
              rows={4}
              variant="standard"
              fullWidth
              value={newLogValue}
              onChange={handleChange}
            />
          </div>
        </Box>
      </CardContent>
      <CardActions style={{ display: "flex", justifyContent: "flex-end" }}>
        <Tooltip title="save">
          <IconButton
            aria-label="save"
            onClick={(e) => {
              handleputLog({
                Timestamp: new Date().toUTCString(),
                Data: { Msg: newLogValue },
              });
            }}
          >
            <SaveIcon />
          </IconButton>
        </Tooltip>
      </CardActions>
    </Card>
  );
}
