import {
  DataGridPro,
  GridRenderCellParams,
  useGridApiContext,
  GridColDef,
  DataGridProProps,
  useGridSelector,
  gridFilteredDescendantCountLookupSelector,
} from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box";
import Business from "@mui/icons-material/Business";
import Button, { ButtonProps } from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import Settings from "@mui/icons-material/Settings";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAuthorizer } from "../../util/authorizer";

const ACTIONS = [
  {
    value: "remove",
    label: "Delete",
    icon: <DeleteIcon />,
  },
  {
    value: "add-sub-organization",
    label: "Add Sub Organization",
    icon: <AddIcon />,
  },
  {
    value: "manage",
    label: "Manage",
    icon: <Settings />,
  },
];

const buildColumns = ({ onActionClick, isOrgAdmin }) => {
  const columns: GridColDef[] = [
    {
      field: "createdAt",
      headerName: "Created At",
      type: "date",
      width: 150,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 150,
      cellClassName: "actions",
      sortable: false,
      renderCell: (row) => {
        return (
          <>
            <Box sx={{ p: 2 }}>
              {ACTIONS?.filter((action) => {
                if (action.value === "remove") {
                  return !isOrgAdmin();
                }
                return true;
              }).map((action) => (
                <IconButton
                  key={action.value}
                  onClick={() => {
                    onActionClick(action.value, row);
                  }}
                  size="small"
                >
                  <Tooltip title={action.label} placement="top">
                    {action.icon}
                  </Tooltip>
                </IconButton>
              ))}
            </Box>
          </>
        );
      },
    },
  ];
  return columns;
};

const getTreeDataPath: DataGridProProps["getTreeDataPath"] = (row) =>
  row.hierarchy;

export const isNavigationKey = (key: string) =>
  key === "Home" ||
  key === "End" ||
  key.indexOf("Arrow") === 0 ||
  key.indexOf("Page") === 0 ||
  key === " ";

function CustomGridTreeDataGroupingCell(props: GridRenderCellParams) {
  const { id, field, rowNode } = props;
  const apiRef = useGridApiContext();
  const filteredDescendantCountLookup = useGridSelector(
    apiRef,
    gridFilteredDescendantCountLookupSelector
  );
  const filteredDescendantCount =
    filteredDescendantCountLookup[rowNode.id] ?? 0;

  const handleClick: ButtonProps["onClick"] = (event) => {
    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
    apiRef.current.setCellFocus(id, field);
    event.stopPropagation();
  };

  return (
    <Box sx={{ ml: rowNode.depth * 4 }}>
      <div>
        {props.row.type === "organization" ? (
          <div>
            <Button onClick={handleClick} tabIndex={-1} size="small">
              <Chip
                label={`${rowNode.groupingKey} (${filteredDescendantCount})`}
                icon={<Business />}
                variant="outlined"
                style={{
                  border: "0",
                }}
              />
            </Button>
          </div>
        ) : null}
      </div>
    </Box>
  );
}
const groupingColDef: DataGridProProps["groupingColDef"] = {
  headerName: "Company",
  renderCell: (params) => <CustomGridTreeDataGroupingCell {...params} />,
  width: 400,
};

export function OrgGrid({ data, error, isLoading, onActionClick }) {
  const { isOrgAdmin } = useAuthorizer();
  return (
    <DataGridPro
      treeData
      rows={data?.rows || []}
      columns={buildColumns({
        onActionClick,
        isOrgAdmin,
      })}
      getTreeDataPath={getTreeDataPath}
      groupingColDef={groupingColDef}
      loading={isLoading}
      error={error}
      initialState={{
        pinnedColumns: {
          left: ["company"],
          right: ["actions"],
        },
      }}
    />
  );
}
