import { configureStore } from "@reduxjs/toolkit";
import MainDataslice from "./MainStore";

const store = configureStore({
  reducer: {
    mainData: MainDataslice,
  },
  devTools: true,
});

//export const persistor = persistStore(store);
export default store;
