import { Navigate, Route, Routes, Outlet } from "react-router-dom";
import { RoleManagementRouter } from "./RoleManagementRouter";
import Box from "@mui/material/Box";

function RootLayout() {
  return (
    <Box>
      <div style={{ paddingTop: 20 }}>
        <Outlet />
      </div>
    </Box>
  );
}

export function RoleManagementAdminRouter() {
  return (
    <Routes>
      <Route path="role-management/*" element={<RootLayout />}>
        <Route path="index/*" element={<RoleManagementRouter />} />
        <Route index element={<Navigate to="index" />} />
      </Route>
    </Routes>
  );
}
