import React from "react";

import Logo from "./img/svg/logo.svg";
import "./App.css";
import {
  AmplifyAuthenticator,
  AmplifySignUp,
  AmplifySignIn,
  AmplifyConfirmSignIn,
  AmplifyConfirmSignUp,
  AmplifyForgotPassword,
} from "@aws-amplify/ui-react";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";
import { API } from "aws-amplify";
import Mainview from "./components/MainView/Mainview";
import { Amplify, Auth, Hub } from "aws-amplify";
import AMPLIFY_CONFIG from "./aws-exports";
import { Routes, Route } from "react-router-dom";
import { AWSIoTProvider } from "@aws-amplify/pubsub";

import { Provider } from "react-redux";
import store from "./components/Redux/Store";

import HP from "./components/HP";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import { AuthorizerProvider } from "./components/util/authorizer";

LicenseInfo.setLicenseKey(
  "591ae9213989658cd9a5194c4379b196Tz04MzM4MCxFPTE3MzgzOTUzNTUwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y"
);

Amplify.configure(AMPLIFY_CONFIG);

Amplify.addPluggable(
  new AWSIoTProvider({
    aws_pubsub_region: "eu-central-1",
    aws_pubsub_endpoint:
      "wss://a2bzxfhckf71q1-ats.iot.eu-central-1.amazonaws.com/mqtt",
  })
);

const checkIfOrgUserNeedFixes = async (data) => {
  const apiName = "ThermonovaAPI";
  const path = "/users/check-if-org-user-need-fixes";
  await API.post(apiName, path, {
    body: data,
  });
};

function App() {
  const [showLogo, setShowLogo] = React.useState("none");
  const [authState, setAuthState] = React.useState<AuthState>();
  const [user, setUser] = React.useState<object>();
  const [vieporth, setVieporth] = React.useState<number>();

  React.useEffect(() => {
    document.title = "Dashboard - Thermonova A/S";
  }, []);

  async function updateUserAttributes(user: object, identID: string) {
    await Auth.updateUserAttributes(user, {
      "custom:IdentityID": identID,
    });
  }

  React.useEffect(() => {
    setVieporth(window.innerHeight / 2 - 350);
    if (user !== undefined && !user["challengeName"]) {
      setShowLogo("none");
      Auth.currentAuthenticatedUser({ bypassCache: false }).then((response) => {
        if (!("custom:IdentityID" in response.attributes)) {
          Auth.currentUserInfo().then((response2) => {
            updateUserAttributes(response, response2.id);
          });
        }
        Auth.currentUserInfo().then((response2) => {
          checkIfOrgUserNeedFixes({
            IdentityID: response2.id,
            Email: response2.attributes.email,
          });
        });
        if (
          "cognito:groups" in
          response["signInUserSession"]["accessToken"]["payload"]
        ) {
          const groups =
            response["signInUserSession"]["accessToken"]["payload"][
              "cognito:groups"
            ];
          if (
            groups?.includes("Administrator") ||
            groups?.filter((group) => group.startsWith("#role"))
          ) {
            // ok()
          } else {
            signOut();
            //window.location.replace('https://my.thermonova.dk')
          }
        } else {
          signOut();
          //window.location.replace('https://my.thermonova.dk')
        }
      });
    }
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData);
      setShowLogo("block");
    });
  }, [user]);

  const signOut = async () => {
    try {
      await Auth.signOut();
      Hub.dispatch("UI Auth", {
        // channel must be 'UI Auth'
        event: "AuthStateChange", // event must be 'AuthStateChange'
        message: "signedout", // message must be 'signedout'
      });
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };
  const LoggedInView = () => (
    <div className="App">
      <Mainview logOut={signOut} />
    </div>
  );
  return authState === AuthState.SignedIn && user ? (
    <Provider store={store}>
      <AuthorizerProvider>
        <Routes>
          <Route path="*" element={<LoggedInView />} />
          <Route path="/HP" element={<HP />} />
        </Routes>
      </AuthorizerProvider>
    </Provider>
  ) : (
    <div>
      <div>
        <img
          src={Logo}
          style={{
            width: "20rem",
            position: "absolute",
            top: vieporth,
            left: "calc(50% - 10rem)",
            display: showLogo,
          }}
          alt="Thermonova A/S"
        />
      </div>

      <AmplifyAuthenticator>
        <AmplifySignIn
          slot="sign-in"
          usernameAlias="email"
          formFields={[
            {
              type: "email",
              label: "Email Address",
              placeholder: "Enter your email address",
              inputProps: {
                id: "signInEmail",
                required: true,
                autocomplete: "username",
              },
            },
            {
              type: "password",
              label: "Password",
              placeholder: "Enter your password",
              inputProps: {
                id: "signInPassword",
                required: true,
                autocomplete: "current-password",
              },
            },
          ]}
        />
        <AmplifyConfirmSignIn slot="confirm-sign-in" />
        <AmplifyConfirmSignUp
          usernameAlias="email"
          slot="confirm-sign-up"
          formFields={[
            {
              type: "code",
              label: "Confirmation Code",
              placeholder: "Enter your confirmation code",
              inputProps: {
                id: "confirmSignUpCode",
                required: true,
                autocomplete: "off",
              },
            },
          ]}
        />
        <AmplifyForgotPassword
          usernameAlias="email"
          slot="forgot-password"
          formFields={[
            {
              type: "email",
              label: "Email Address",
              placeholder: "Enter your email address",
              inputProps: {
                id: "forgotPasswordEmail",
                required: true,
                autocomplete: "username",
              },
            },
          ]}
        />
        <AmplifySignUp
          slot="sign-up"
          usernameAlias="email"
          formFields={[
            {
              type: "email",
              label: "Email Address",
              placeholder: "Enter your email address",
              inputProps: {
                id: "signUpEmail",
                required: true,
                autocomplete: "username",
              },
            },
            {
              type: "password",
              label: "New password",
              placeholder: "Enter a new password",
              inputProps: {
                id: "signUpPassword",
                required: true,
                autocomplete: "new-password",
              },
            },
          ]}
        />
      </AmplifyAuthenticator>
    </div>
  );
}

export default App;
