import React from "react";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

import ActualValuesLogic from "./ActualValuesLogic";

export default function ActualValuesView() {
  const {
    dispatch,
    liveDataset,
    showValues,
    actualValues,
    setOpenEditDatasetModal,
  } = ActualValuesLogic();

  return (
    <Box
      sx={{
        borderRadius: "4px",
        border: "1px solid rgba(0, 0, 0, 0.23)",
        margin: "10px 10px 10px 0px",
      }}
    >
      <div>
        {liveDataset.map((s: any) => {
          return showValues(
            s.controller,
            s.name,
            actualValues[s.controller + s.name],
            s.color,
            s.adr
          );
        })}
      </div>
      <Stack sx={{ pt: "5px" }} direction="row" justifyContent="center">
        <IconButton
          color="primary"
          aria-label="Edit"
          onClick={() => dispatch(setOpenEditDatasetModal(true))}
        >
          <EditIcon fontSize="small" />
        </IconButton>
      </Stack>
    </Box>
  );
}
