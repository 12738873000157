import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

import HPInformationsLogic from './HPInformationsLogic'



export default function HPInformationsView() {
    const {Item, rows} = HPInformationsLogic()



  return (
      <Item>
            <TableContainer >
              <Table size="small" aria-label="a dense table">
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell component="th" scope="row"> {row.name} </TableCell>
                      <TableCell align="left">{row.value}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
        </TableContainer>
      </Item>
    
  )
}
