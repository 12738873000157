import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate, useSearchParams } from "react-router-dom";
import { API } from "aws-amplify";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import useSWR, { useSWRConfig } from "swr";
import CircularProgress from "@mui/material/CircularProgress";

const templateFetcher = (id) => {
  const apiName = "ThermonovaAPI";
  const path = "/role/get";
  return API.get(apiName, path, {
    queryStringParameters: { id },
  });
};

export default function RemoveRole() {
  const [busy, setBusy] = React.useState(false);
  const [error, setError] = React.useState("");
  const [success, setSuccess] = React.useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const templateId = searchParams.get("id");

  const handleClose = () => {
    navigate("/role-management/index");
  };

  const {
    data: template,
    error: errorFetchingTemplate,
    isLoading: isLoadingTemplate,
  } = useSWR(templateId, templateFetcher);
  const { mutate } = useSWRConfig();

  const onConfirm = async () => {
    try {
      const apiName = "ThermonovaAPI";
      const path = "/role/remove";
      setBusy(true);
      await API.post(apiName, path, {
        body: {
          roleId: templateId,
        },
      });
      setSuccess(true);
      handleClose();
      mutate("roleTemplates");
    } catch (error) {
      const errorText = error.message || error;
      setError(errorText);
    } finally {
      setBusy(false);
    }
  };

  return (
    <>
      <Dialog
        open
        onClose={handleClose}
        style={{
          // TODO: Not sure if this is the best way to set the height
          height: "80vh",
        }}
      >
        <DialogTitle>Remove Template</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to remove <b>{template?.name}</b>.
          </DialogContentText>

          {errorFetchingTemplate && (
            <Alert severity="error">{errorFetchingTemplate}</Alert>
          )}
          {isLoadingTemplate ? <CircularProgress /> : <div></div>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            disabled={busy}
            onClick={onConfirm}
            variant="contained"
            color="error"
          >
            Remove
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={success}
        autoHideDuration={4000}
        onClose={() => {
          setSuccess(false);
        }}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Template removed successfully
        </Alert>
      </Snackbar>
      <Snackbar
        open={error !== ""}
        autoHideDuration={4000}
        onClose={() => {
          setError("");
        }}
      >
        <Alert
          onClose={handleClose}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>
    </>
  );
}
