import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate, useSearchParams } from "react-router-dom";
import { API } from "aws-amplify";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Alert from "@mui/material/Alert";
import useSWR from "swr";

import { CircularProgress } from "@mui/material";
import { OverviewTab } from "./Overview";
import { UsersTab } from "./Users";
import { HeatpumpsTab } from "./Heatpumps";
import { ManageAccessRights } from "./ManageAccessRights";
import { useAuthorizer } from "../../util/authorizer";
function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}
const fetcher = (id) => {
  const apiName = "ThermonovaAPI";
  const path = "/organization/list";
  return API.get(apiName, path, {
    queryStringParameters: {
      id,
    },
  });
};

export default function ManageOrganization() {
  const [searchParams] = useSearchParams();
  const organizationId = searchParams.get("organization");
  const { isAdministrator } = useAuthorizer();

  const {
    data,
    error: errorFetchingOrganization,
    isLoading,
  } = useSWR(organizationId, fetcher);
  const organization = data?.organization;
  const { userId } = useAuthorizer();

  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleClose = () => {
    navigate("/access-management/company");
  };

  const adminRights =
    isAdministrator ||
    (organization?.ownerId && organization?.ownerId !== userId);
  return (
    <>
      <Dialog open onClose={handleClose} fullScreen>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Manage Organization
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogTitle>
          {isLoading ? "Loading..." : organization?.name}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Organizations are used to group users, so they will have scoped
            access to Thermonova Portal. Please enter the name of the
            organization you would like to create.
          </DialogContentText>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Company" {...a11yProps(0)} />
              <Tab label="Users" {...a11yProps(1)} />
              <Tab label="Heat pumps" {...a11yProps(2)} />
              {adminRights && <Tab label="Access Rights" {...a11yProps(3)} />}
            </Tabs>
          </Box>
          <Box>
            {isLoading && (
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                <CircularProgress />
              </div>
            )}
            {organization ? (
              <>
                {value === 0 && <OverviewTab organization={organization} />}
                {value === 1 && <UsersTab organization={organization} />}
                {value === 2 && (
                  <HeatpumpsTab
                    organization={organization}
                    canAssignHPs={adminRights}
                    canRemoveHPs={adminRights}
                  />
                )}

                {value === 3 && adminRights && (
                  <ManageAccessRights organization={organization} />
                )}
              </>
            ) : (
              !isLoading && (
                <Alert severity="error">Organization not found</Alert>
              )
            )}
          </Box>

          {(errorFetchingOrganization || !data?.organization?.sk) &&
            !isLoading && (
              <Alert
                severity="error"
                style={{
                  marginTop: "20px",
                }}
              >
                {errorFetchingOrganization?.message ||
                  errorFetchingOrganization ||
                  "Organization not found"}
              </Alert>
            )}
        </DialogContent>
      </Dialog>
    </>
  );
}
