import { createSlice } from "@reduxjs/toolkit";

interface MainData {
  heatpumps: any;
  filteredHeatpumps: any;
  heatpumpsFilterText: string;
  gateways: any;
  softwares: any;
}

const initialState: MainData = {
  heatpumps: [],
  filteredHeatpumps: [],
  heatpumpsFilterText: "",
  gateways: {},
  softwares: {}
};
export const MainDataslice = createSlice({
  name: "maindata",
  initialState,
  reducers: {
    setHeatpumps: (
      state,
      action) =>{
      state.heatpumps = action.payload;
    },
    setFilteredHeatpumps: (
      state,
      action) =>{
      state.filteredHeatpumps = action.payload;
    },
    setHeatpumpsFilterText: (
      state,
      action) =>{
      state.heatpumpsFilterText = action.payload;
    },
    setGateways: (
      state,
      action) =>{
      state.gateways = action.payload;
    },
    setSoftwares: (
      state,
      action) =>{
      state.softwares = action.payload;
    }
    
  },
});

// Action creators are generated for each case reducer function
export const {
  setHeatpumps,
  setFilteredHeatpumps,
  setHeatpumpsFilterText,
  setGateways,
  setSoftwares
} = MainDataslice.actions;

export default MainDataslice.reducer;
