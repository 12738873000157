import React from "react";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import ChooseFile from "./ChooseFile";
import ChooseControllerView from "./ChooseControllerView";
import UploadSoftwareView from "./UploadSoftwareView";

import { useDispatch } from "react-redux";
import { setChooseController } from "../Redux/HP";

export default function SoftwareUploadLogic() {
  const dispatch = useDispatch();

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = ["Select file", "Select Controller"];

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    dispatch(setChooseController({ "1": false, "2": false, "100": false }));
  };

  const getStep = () => {
    switch (activeStep) {
      case 0:
        return <ChooseFile />;
      case 1:
        return <ChooseControllerView />;
      case 2:
        return <UploadSoftwareView activeStep={activeStep} />;

      default:
        return <div>default</div>;
    }
  };

  return { Item, activeStep, steps, getStep, handleBack, handleNext };
}
