import React from "react";
import { API } from "aws-amplify";

import { useSelector, useDispatch } from "react-redux";
import { setLogs } from "../Redux/HP";

export default function LogInputLogic() {
  const { HPData } = useSelector((state: any) => state.heatpump);
  const dispatch = useDispatch();

  const [newLogValue, setNewLogValue] = React.useState("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewLogValue(event.target.value);
  };

  async function APIputLog(serialnumber: string, data: any) {
    // data = {'Timestamp': '2022-04-27 12:07:21 UTC', 'Data':{"Msg": "Third log","User": "ts@topdrive.dk"}}
    const apiName = "ThermonovaAPI";
    const path = "/HP/logs/log";
    const myInit = {
      // OPTIONAL
      body: {
        Serialnumber: serialnumber,
        newLog: data,
      }, // replace this with attributes you need
    };

    return await API.put(apiName, path, myInit);
  }
  async function APIgetLogs(serialnumber: string) {
    const apiName = "ThermonovaAPI";
    const path = "/HP/logs";
    const myInit = {
      // OPTIONAL
      queryStringParameters: {
        Serialnumber: serialnumber,
      }, // replace this with attributes you need
    };

    return await API.get(apiName, path, myInit);
  }

  const getLogs = () => {
    APIgetLogs(HPData.Serialnumber).then((response) => {
      //console.log(response)
      dispatch(setLogs(response));
    });
  };

  const handleputLog = (data: any) => {
    //console.log(TimeStamp)
    APIputLog(HPData.Serialnumber, data).then((response) => {
      //trigger reload
      getLogs();
      setNewLogValue("");
    });
  };

  return { handleputLog, newLogValue, handleChange };
}
