import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { EditDatasetLogic } from "./EditDatasetLogic";

import Grid from "@mui/material/Grid";
import List from "@mui/material/List";

export default function EditDatasetView(props: any) {
  const {
    getParamsList,
    getDatasetList,
    showColorPicker,
    openEditDatasetModal,
    handleCloseEditDatasetModal,
  } = EditDatasetLogic(props.webWorker, props.context);

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth={"lg"}
        open={openEditDatasetModal}
        onClose={handleCloseEditDatasetModal}
      >
        <DialogTitle>Edit Dataset</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={5} style={{ height: "80vh", overflow: "auto" }}>
              <List
                sx={{
                  width: "100%",
                  maxWidth: 400,
                  bgcolor: "background.paper",
                }}
                component="nav"
              >
                {getParamsList()}
              </List>
            </Grid>
            <Grid item xs={7}>
              {getDatasetList()}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button disabled={false} onClick={handleCloseEditDatasetModal}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
      {showColorPicker()}
    </div>
  );
}
