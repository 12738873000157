import * as React from "react";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
// import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { AddSoftwareLogic } from "./AddSoftwareLogic";

// type AddGatewayViewProps = {
//   handleClose: () => void;
//   open: boolean;
// };

export default function AddGatewayView(props) {
  const {
    handleSend,
    returnAlert,
    handleChange,
    triggerFileInput,
    files,
    handleClear,
    disabledSendButton,
    loading,
    loading2,
  } = AddSoftwareLogic();

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={() => {
          handleClear(true);
          props.handleClose();
        }}
      >
        <DialogTitle>Add New Software</DialogTitle>
        <DialogContent>
          {/* <div>
            <label htmlFor="folder">Select folder</label>
          </div> */}
          <Button
            onClick={triggerFileInput}
            variant="outlined"
            disabled={loading || loading2}
          >
            Select folder
          </Button>
          <br />
          {files && files?.length > 0 ? (
            <small>
              <i>1 Item selected</i>
            </small>
          ) : (
            <></>
          )}

          {loading && (
            <>
              <br />
              <small>
                <i>Uploading</i>
              </small>
            </>
          )}
          {loading2 && (
            <>
              <br />
              <small>
                <i>Generating zip. Please wait...</i>
              </small>
            </>
          )}
          <input
            type="file"
            name="files[]"
            id="files"
            multiple=""
            directory=""
            webkitdirectory=""
            mozdirectory=""
            style={{ visibility: "hidden" }}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClear}>Clear</Button> */}
          <Button
            disabled={disabledSendButton || loading || loading2}
            variant="contained"
            color="primary"
            onClick={handleSend}
          >
            {!loading && !loading2 && <span>Upload</span>}
            {(loading || loading2) && <CircularProgress size={20} />}
          </Button>
        </DialogActions>
        {returnAlert()}
      </Dialog>
    </div>
  );
}
