import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Icon from "@mui/material/Icon";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#FFFFFF",
  ...theme.typography.body2,
  padding: theme.spacing(3),
  color: theme.palette.text.secondary,
  borderRadius: 16,
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  transition: "all 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
  },
}));

const StatItem = ({ icon = null, title, subtitle = "", value, color }) => (
  <Item
    style={{
      height: "200px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    }}
  >
    {icon && (
      <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
        <Icon component={icon} style={{ fontSize: 40, color: color }} />
      </Box>
    )}
    <Typography
      variant="h5"
      align="center"
      style={{ color: "#394B53", fontWeight: "bold" }}
    >
      {title}
    </Typography>
    {subtitle && (
      <Typography variant="body1" align="center" style={{ color: "#394B53" }}>
        {subtitle}
      </Typography>
    )}
    <Typography
      variant="h4"
      align="center"
      style={{ color: color, fontWeight: "bold" }}
    >
      {value}
    </Typography>
  </Item>
);

export { StatItem, Item };
