import {
  Alert,
  Box,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Stack,
  Switch,
  TextField,
  Tooltip,
} from "@mui/material";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Outlet } from "react-router-dom";
import { useMemo, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useSWRConfig } from "swr";
import { API } from "aws-amplify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useAuthorizer } from "../../../util/authorizer";

interface Inputs {
  user_name?: string;
  email?: string;
  phone?: string;
  add_hp_auto?: boolean;
}

const userFormSchema = yup
  .object()
  .shape({
    user_name: yup.string(),
    email: yup.string().email().required(),
    phone: yup.string(),
    add_hp_auto: yup.boolean(),
  })
  .required();

enum ViewType {
  LIST = "list",
  ADD = "add",
  MANAGE = "manage",
}

const apiName = "ThermonovaAPI";

const updateOrganizationUser = async (organization, user) => {
  const path = "/organization/update-user";
  await API.post(apiName, path, {
    body: {
      organization,

      user,
    },
  });
};

const addOrganizationUser = async (organization, user) => {
  const path = "/organization/add-user";
  await API.post(apiName, path, {
    body: {
      organization,
      user,
    },
  });
};

const deleteOrganizationUser = async (organization, user) => {
  const path = "/organization/delete-user";
  await API.post(apiName, path, {
    body: {
      organization,
      user,
    },
  });
};

export function UsersTab({ organization }) {
  const [activeView, setActiveView] = useState({
    view: ViewType.LIST,
    meta: null,
  });
  const { mutate } = useSWRConfig();
  const handleActionClick = (action, node) => {
    switch (action) {
      case "delete":
        deleteOrganizationUser(organization, node.row).then(() => {
          mutate("organizations");
          mutate(organization.sk);
        });
        break;
      case "manage":
        setActiveView({
          view: ViewType.MANAGE,
          meta: node.row,
        });
        break;
      default:
        console.error("Unknown action", action);
    }
  };

  return (
    <div>
      <Paper sx={{ width: "100%", overflow: "hidden", position: "relative" }}>
        {activeView.view === ViewType.LIST && (
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
            spacing={2}
          >
            <Box component="section" sx={{ p: 2 }}>
              <Tooltip title="Add User" placement="top">
                <Button
                  variant="contained"
                  onClick={() => {
                    setActiveView({
                      view: ViewType.ADD,
                      meta: null,
                    });
                  }}
                >
                  <AddIcon style={{ fontSize: 20, marginRight: 5 }} />
                  Add User
                </Button>
              </Tooltip>
            </Box>
          </Stack>
        )}
        <Box sx={{ height: "80vh", padding: 2 }}>
          {activeView.view === ViewType.LIST && (
            <UsersGrid
              data={organization?.users}
              onActionClick={handleActionClick}
            />
          )}
          {activeView.view === ViewType.ADD && (
            <UsersAddManageForm
              onSuccessfulSubmit={() => {
                setActiveView({
                  view: ViewType.LIST,
                  meta: null,
                });
              }}
              onCancel={() => {
                setActiveView({
                  view: ViewType.LIST,
                  meta: null,
                });
              }}
              organization={organization}
            />
          )}
          {activeView.view === ViewType.MANAGE && (
            <UsersAddManageForm
              onSuccessfulSubmit={() => {
                setActiveView({
                  view: ViewType.LIST,
                  meta: null,
                });
              }}
              onCancel={() => {
                setActiveView({
                  view: ViewType.LIST,
                  meta: null,
                });
              }}
              organization={organization}
              row={activeView.meta}
            />
          )}
        </Box>
        <Outlet />
      </Paper>
    </div>
  );
}

const ACTIONS = [
  {
    value: "delete",
    label: "delete",
    icon: <DeleteIcon />,
  },
  {
    value: "manage",
    label: "Manage",
    icon: <EditIcon />,
  },
];

const buildColumns = ({ onActionClick, orgOwner: isOrgOwner }) => {
  const columns: GridColDef[] = [
    { field: "user_name", headerName: "User Name", width: 200 },
    { field: "email", headerName: "Email", width: 200 },
    { field: "phone", headerName: "Phone", width: 200 },
    { field: "add_hp_auto", headerName: "Add HP Automatically", width: 200 },
    {
      field: "createdAt",
      headerName: "Created At",
      type: "date",
      width: 150,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 150,
      cellClassName: "actions",
      sortable: false,
      renderCell: (row) => {
        return (
          <>
            <Box sx={{ p: 2 }}>
              {ACTIONS?.filter((action) => {
                if (action.value === "delete") {
                  return !isOrgOwner;
                }
                return true;
              }).map((action) => (
                <IconButton
                  key={action.value}
                  onClick={() => {
                    onActionClick(action.value, row);
                  }}
                  size="small"
                >
                  <Tooltip title={action.label} placement="top">
                    {action.icon}
                  </Tooltip>
                </IconButton>
              ))}
            </Box>
          </>
        );
      },
    },
  ];
  return columns;
};

export function UsersGrid({ data, onActionClick }) {
  console.log({ data });
  return (
    <DataGridPro
      rows={data || []}
      columns={buildColumns({
        onActionClick,
        orgOwner: data[0]?.["orgOwner"],
      })}
      initialState={{
        pinnedColumns: {
          left: ["company"],
          right: ["actions"],
        },
      }}
    />
  );
}

interface UsersAddManageFormProps {
  onSuccessfulSubmit: () => void;
  onCancel: () => void;
  organization: any;
  row?: any;
}

export function UsersAddManageForm(props: UsersAddManageFormProps) {
  const [busy, setBusy] = useState(false);
  const [error, setError] = useState(null);
  const { mutate } = useSWRConfig();
  const { onSuccessfulSubmit, organization } = props;

  const mode = props.row ? "edit" : "add";

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: useMemo(() => {
      if (props.row) {
        return {
          user_name: props.row.user_name,
          email: props.row.email,
          phone: props.row.phone,
          add_hp_auto: props.row.add_hp_auto,
        };
      }
      return {
        user_name: "",
        email: "",
        phone: "",
        add_hp_auto: true,
      };
    }, [props.row]),
    resolver: yupResolver<Inputs>(userFormSchema),
  });

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const execute = async () => {
      setBusy(true);
      setError(null);
      try {
        if (mode === "edit") {
          await updateOrganizationUser(organization, {
            id: props.row.id,
            sk: props.row.sk,
            ...data,
          });
        } else if (mode === "add") {
          const emailExists = organization?.users?.find(
            (user) => user.email === data.email
          );

          if (emailExists) {
            throw new Error("Email already exists");
          }

          await addOrganizationUser(organization, data);
        }
        mutate("organizations");
        mutate(organization.sk);
        onSuccessfulSubmit();
      } catch (error) {
        const beMessage = error?.response?.data?.message;
        const errorText = beMessage || error.message || "Something went wrong";
        setError(errorText);
      } finally {
        setBusy(false);
      }
    };
    execute();
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ py: 4 }}>
          <Grid container spacing={4}>
            <Grid item xs={6} md={8}>
              <TextField
                autoFocus
                required
                margin="dense"
                id="name"
                label="User Name"
                type="text"
                fullWidth
                {...register("user_name")}
                error={!!errors.user_name}
                size="small"
              />
            </Grid>

            <Grid item xs={12} md={8}>
              <TextField
                margin="dense"
                id="usr_email"
                label="Email"
                type="text"
                fullWidth
                {...register("email")}
                error={!!errors.email}
                size="small"
                disabled={mode === "edit"}
              />
            </Grid>

            <Grid item xs={12} md={8}>
              <TextField
                margin="dense"
                id="phone"
                label="Phone"
                type="text"
                fullWidth
                {...register("phone")}
                error={!!errors.phone}
                size="small"
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <Controller
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Switch
                        {...field}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    }
                    label="Add HP Automatically"
                  />
                )}
                control={control}
                name="add_hp_auto"
              />
            </Grid>
            {error && (
              <Grid item xs={12} md={8}>
                <Alert severity="error">{error}</Alert>
              </Grid>
            )}
            <Grid item xs={12} md={8}>
              <Button
                onClick={() => {
                  props.onCancel();
                }}
                disabled={busy}
              >
                Cancel
              </Button>
              <Button type="submit" disabled={busy}>
                {mode === "edit" ? "Save" : "Add"}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </form>
    </div>
  );
}
