import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputAdornment from "@mui/material/InputAdornment";
import Email from "@mui/icons-material/Email";
import Business from "@mui/icons-material/Business";
import { useNavigate, useSearchParams } from "react-router-dom";
import { API } from "aws-amplify";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useSWRConfig } from "swr";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { AccessRightsForm } from "../../AccessRightsForm";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

const steps = ["Company Details", "Access Rights"];

const companyFormSchema = yup
  .object()
  .shape({
    name: yup.string().required().label("Name"),
    administrator_email: yup
      .string()
      .email()
      .required()
      .label("Administrator Email"),
  })
  .required();

type Inputs = {
  name?: string;
  administrator_email?: string;
};

export default function AddOrganization() {
  const { mutate } = useSWRConfig();
  const [activeStep, setActiveStep] = React.useState(0);
  const [busy, setBusy] = React.useState(false);
  const [error, setError] = React.useState("");
  const [success, setSuccess] = React.useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const methods = useForm<Inputs>({
    defaultValues: React.useMemo(() => {
      return {
        name: "",
        administrator_email: "",
      };
    }, []),
    resolver: yupResolver<Inputs>(companyFormSchema),
  });

  const handleClose = () => {
    navigate("/access-management/company");
  };

  const handleNext = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (activeStep === 0) {
      if (!methods.formState.isValid) {
        methods.trigger();
        return;
      }
    }

    if (activeStep === steps.length - 1) {
      return;
    } else {
      event.preventDefault();
      event.stopPropagation();
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const execute = async () => {
      try {
        let parent = searchParams.get("parent");

        parent = parent ? decodeURIComponent(parent) : null;
        const apiName = "ThermonovaAPI";
        const path = "/organization/add";
        setBusy(true);
        const { organization } = await API.post(apiName, path, {
          body: {
            ...data,
            parent,
          },
        });
        mutate("organizations");
        if (!organization.pk) {
          throw new Error("Organization not created");
        }
        setSuccess(true);
        handleClose();
      } catch (error) {
        const errorText = error.message || error;
        setError(errorText);
      } finally {
        setBusy(false);
      }
    };
    execute();
  };

  return (
    <>
      <Dialog
        open
        maxWidth="md"
        fullWidth
        onClose={handleClose}
        PaperComponent={Paper}
      >
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <DialogTitle sx={{ bgcolor: "primary.main", color: "white" }}>
              Create Company
            </DialogTitle>

            <DialogContent sx={{ py: 4 }}>
              <Box
                sx={{
                  borderRadius: "12px",
                  padding: "20px",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  mb: 4,
                  mt: 2,
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "#394B53",
                    fontWeight: "bold",
                    mb: 2,
                  }}
                >
                  Create Your Organization
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: "#394B53",
                    lineHeight: 1.6,
                  }}
                >
                  Organizations in Thermonova Portal allow you to group users
                  and manage their access efficiently. Start by entering your
                  organization's name below to create a tailored workspace for
                  your team.
                </Typography>
              </Box>
              <Stepper activeStep={activeStep} sx={{ mb: 5 }}>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>

              <Box sx={{ mt: 4 }}>
                {activeStep === 0 ? (
                  <>
                    <TextField
                      autoFocus
                      required
                      size="small"
                      margin="dense"
                      variant="outlined"
                      label="Name"
                      type="text"
                      error={!!methods.formState.errors.name}
                      helperText={methods.formState.errors.name?.message}
                      fullWidth
                      {...methods.register("name")}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Business color="primary" />
                          </InputAdornment>
                        ),
                      }}
                      sx={{ mb: 3 }}
                    />
                    <TextField
                      required
                      margin="dense"
                      label="Administrator Email"
                      size="small"
                      variant="outlined"
                      type="email"
                      {...methods.register("administrator_email")}
                      fullWidth
                      error={!!methods.formState.errors.administrator_email}
                      helperText={
                        methods.formState.errors.administrator_email?.message
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Email color="primary" />
                          </InputAdornment>
                        ),
                      }}
                      sx={{ mb: 3 }}
                    />
                  </>
                ) : (
                  <AccessRightsForm />
                )}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mt: 5,
                  }}
                >
                  <Button
                    onClick={handleClose}
                    color="secondary"
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                  <Box>
                    <Button
                      color="secondary"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mr: 2 }}
                      variant="outlined"
                    >
                      Back
                    </Button>
                    <Button
                      onClick={handleNext}
                      variant="contained"
                      color="primary"
                      {...(activeStep === steps.length - 1
                        ? { type: "submit", disabled: busy }
                        : {})}
                    >
                      {activeStep === steps.length - 1 ? "Finish" : "Next"}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </DialogContent>
          </form>
        </FormProvider>
      </Dialog>
      <Snackbar
        open={success}
        autoHideDuration={4000}
        onClose={() => {
          setSuccess(false);
        }}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Organization created successfully!
        </Alert>
      </Snackbar>
      <Snackbar
        open={error !== ""}
        autoHideDuration={4000}
        onClose={() => {
          setError("");
        }}
      >
        <Alert
          onClose={handleClose}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>
    </>
  );
}
