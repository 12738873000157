import React from "react";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PendingIcon from "@mui/icons-material/Pending";
import Button from "@mui/material/Button";

import UploadSoftwareLogic from "./UploadSoftwareLogic";
export default function UploadSoftwareView(props: any) {
  const {
    mcxFileOnGateway,
    mcxFileUploadProgress,
    uploadFile,
    disabledUploadBtn,
    mcxGWRestarted,
  } = UploadSoftwareLogic(props);
  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <Box sx={{ width: "100%", ml: 5 }}>
          <Stack spacing={2} direction="row">
            {mcxFileOnGateway ? (
              <CheckCircleIcon color="primary" />
            ) : (
              <PendingIcon />
            )}
            Send file to gateway.
          </Stack>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ width: "100%", ml: 5 }}>
          <Stack spacing={2} direction="row">
            {mcxFileUploadProgress < 100 ? (
              <PendingIcon />
            ) : (
              <CheckCircleIcon color="primary" />
            )}
            Upload software to MCX.
          </Stack>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ width: "100%", ml: 5 }}>
          <Stack spacing={2} direction="row">
            {mcxGWRestarted === "done" ? (
              <CheckCircleIcon color="primary" />
            ) : (
              <PendingIcon />
            )}
            Restarting gateway.
          </Stack>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ display: "flex", alignItems: "center", m: 5 }}>
          <Box sx={{ width: "100%", mr: 1 }}>
            <LinearProgress
              variant="determinate"
              value={mcxFileUploadProgress}
            />
          </Box>
          <Box sx={{ minWidth: 35 }}>
            <Typography variant="body2" color="text.secondary">{`${Math.round(
              mcxFileUploadProgress
            )}%`}</Typography>
          </Box>
        </Box>
        <Button disabled={disabledUploadBtn} onClick={uploadFile}>
          Upload
        </Button>
      </Grid>
    </Grid>
  );
}
