import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import SoftwareUploadLogic from "./SoftwareUploadLogic";
import ControlButtonsView from "./ControlButtonsView";

export default function SoftwareUpload() {
  const { Item, activeStep, steps, getStep, handleBack, handleNext } =
    SoftwareUploadLogic();
    //   props.HPData,
    //   props.FileUploaded,
    //   props.UploadProgress,
    //   props.mcxGatewayRestared

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Item>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Stepper activeStep={activeStep}>
                  {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              </Grid>

              <Grid item xs={12}>
                {getStep()}
              </Grid>
              <Grid item xs={12}>
                <ControlButtonsView
                  activeStep={activeStep}
                  steps={steps}
                  handleBack={handleBack}
                  handleNext={handleNext}
                />
              </Grid>
            </Grid>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
}
