import useSWR from "swr";
import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import debounce from "@mui/material/utils/debounce";

const fetcherHeatpumps = () => {
  const apiName = "ThermonovaAPI";
  const path = "/heatpumps";
  return API.get(apiName, path, {});
};

export const useHeatpumpData = () => {
  const { data, error, isLoading } = useSWR("heatpumps", fetcherHeatpumps, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if (!data) {
      return;
    }

    if (!searchQuery) {
      setFilteredData(data);
      return;
    }

    const filterDataFnWithDebounce = debounce((data, searchQuery) => {
      setFilteredData(
        data.filter((heatpump) => {
          const name = heatpump?.Name;
          const serialNumber = heatpump?.Serialnumber;
          if (!name || !serialNumber) {
            return false;
          }
          return (
            name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            serialNumber.toLowerCase().includes(searchQuery.toLowerCase())
          );
        })
      );
    }, 300);
    filterDataFnWithDebounce(data, searchQuery);
  }, [searchQuery, data]);

  return {
    error,
    isLoading,
    searchQuery,
    setSearchQuery,
    data: filteredData,
    allHPs: data,
  };
};
