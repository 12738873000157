import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import { DeleteGWLogic } from "./DeleteGatewayLogic";

export default function DeleteGatewayView(props: any) {
  const { handleSend, alert, loading } = DeleteGWLogic(props);

  return (
    <div>
      <Dialog open={props.open} onClose={props.handleClose}>
        <DialogTitle>Delete Gateway</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this Gateway?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose}>Cancel</Button>
          <Button
            disabled={loading}
            onClick={() => handleSend(props.selectedGatewayID)}
          >
            {loading ? <CircularProgress size={20} /> : "Delete"}
          </Button>
        </DialogActions>
        {alert.show && <Alert severity={alert.type}>{alert.message}</Alert>}
      </Dialog>
    </div>
  );
}
