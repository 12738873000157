import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { ConnectGWDialogLogic } from "./ConnectGWDialogLogic";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function FormDialog(props: any) {
  const { handleSend, gwID, handleGWidChange, returnAlert, gatewayIDs } =
    ConnectGWDialogLogic(
      props.handleClose,
      props.serialnumber,
      props.setSerialnumber,
      props.updateHPdata,
      props.open
    );

  return (
    <div>
      <Dialog open={props.open} onClose={props.handleClose}>
        <DialogTitle>Connect Gateway</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To connect a gateway to this heatpump ({props.serialnumber}), please
            select the gateway id.
          </DialogContentText>
          <DialogContentText>
            If Gateway ID is missing, the gateway is not connected.
          </DialogContentText>

          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="demo-simple-select-standard-label">
              Gateway ID
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={gwID}
              onChange={handleGWidChange}
              label="Gateway ID"
            >
              {gatewayIDs}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSend}>Connect</Button>
        </DialogActions>
        {returnAlert()}
      </Dialog>
    </div>
  );
}
