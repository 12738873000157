import { AlertColor } from "@mui/material/Alert";
import { useState } from "react";
import { API } from "aws-amplify";
import { removeGateway } from "../../../reducers/gatewayReducer";
import { useDispatch } from "react-redux";

export const DeleteGWLogic = (props: any) => {
  const Dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    show: false,
    type: "success" as AlertColor,
    message: "",
  });

  const clear = () => {
    setLoading(false);
    setAlert({
      show: false,
      type: "success",
      message: "",
    });
    props.handleClose();
  };

  const handleSend = async (gatewayID: string) => {
    const apiName = "ThermonovaAPI";
    const path = "/gateways";
    setLoading(true);

    await API.del(apiName, path, { body: { gatewayID } })
      .then((response) => {
        if (response === "GW deleted") {
          Dispatch(removeGateway(gatewayID));

          setAlert({
            show: true,
            type: "success",
            message: "Gateway deleted successfully",
          });

          setTimeout(() => {
            clear();
          }, 4000);
        } else {
          setAlert({
            show: true,
            type: "error",
            message: "Something went wrong. Please try again",
          });
          clear();
        }
      })
      .catch((error) => {});
  };

  return {
    handleSend,
    alert,
    loading,
  };
};
