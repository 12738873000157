import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import { DashboardLogic } from "./dashboardLogic";

export default function DashboardView() {
  const {
    viewNumberofheatpumps,
    viewNumberofheatpumpsWithError,
    viewNumberofheatpumpsOnline,
  } = DashboardLogic();

  return (
    <Box id="Dashboard" sx={{ flexGrow: 1 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={3}>
          {viewNumberofheatpumps}
        </Grid>
        <Grid item xs={12} md={12} lg={3}>
          {viewNumberofheatpumpsOnline}
        </Grid>
        <Grid item xs={12} md={12} lg={3}>
          {viewNumberofheatpumpsWithError}
        </Grid>
      </Grid>
    </Box>
  );
}
