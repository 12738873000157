import React, { useState } from "react";
import { API } from "aws-amplify";
import Alert from "@mui/material/Alert";
import Checkbox from "@mui/material/Checkbox";
import { IconButton } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { GridColDef } from "@mui/x-data-grid";



type RowData = {
  mailmessage: boolean;
  phonemessage: boolean;
  name: string;
  email: string;
  phone: string;
  language: string;
  id: string;
};
export const EditCLLogic = (data: any) => {
  const serviceMail = "ts@topdrive.dk";
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [serviceContact, setServiceContact] = useState(false)
  const [pressedAddService, setPressedAddService] = useState(false)
  const [language, setLanguage] = useState("DK");
  const [loading, setLoading] = useState(false);
  const [APIalarm, setAPIalarm] = React.useState(0);
  const [rows, setRows] = React.useState<RowData[]>([]);

  const [phonemessage, setPhonemessage] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [mailmessage, setMailmessage] = useState<{ [key: string]: boolean }>(
    {}
  );

  const handleSend = () => {
    const exists = rows.find(
      (item) => item?.email === email || item?.phone === phone
    );
    if (exists) {
      setAPIalarm(1);
      return;
    }

    setAPIalarm(0);

    setLoading(true);
    postAddContact()
      .then((response) => {
        const r: RowData[] = response?.Contacts?.map((item: any) =>
          createData(item)
        );

        const m: { [key: string]: boolean } = {};
        const p: { [key: string]: boolean } = {};
        r?.forEach((item) => {
          m[item?.id] = item?.mailmessage;
          p[item?.id] = item?.phonemessage;
        });

        setMailmessage(m);
        setPhonemessage(p);

        setRows(r);
        setLoading(false);
        handleAPIResponse("updated");
      })
      .catch((error) => {
        console.log(error);
        handleAPIResponse("error");
        setLoading(false);
      });
  };

  const handleDelete = (row: RowData) => {
    deleteContact(row)
      .then((response) => {
        const r = response?.Contacts?.map((item: any) => createData(item));

        setRows(r);
        setLoading(false);
        handleAPIResponse("deleted");
        if(row.email === serviceMail){
          setServiceContact(false);
          setPressedAddService(false);
        }
      })
      .catch((error) => {
        console.log(error);
        handleAPIResponse("error");
      });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let switchValue = e.target.name;
    switch (switchValue) {
      case "phone":
        setPhone(e.target.value);
        break;
      case "email":
        setEmail(e.target.value);
        break;
      case "name":
        setName(e.target.value);
        break;
      case "language":
        setLanguage(e.target.value);
        break;
      default:
        break;
    }
  };

  const handleAPIResponse = (e: string) => {
    //console.log(e)
    if (e === "GW still connected") {
      setAPIalarm(1);
    } else if (e === "deleted") {
      setAPIalarm(2);
    } else if (e === "updated") {
      setAPIalarm(3);
      setEmail("");
      setPhone("");
      setName("");
      setLanguage("DK")
    }

    clearAlert();
  };

  async function deleteContact(row: RowData) {
    //console.log(row)
    const apiName = "ThermonovaAPI";
    const path = "/HP/contact";
    const myInit = {
      body: {
        email: row.email,
        phone: row.phone,
        emailmessage: row?.mailmessage,
        phonemessage: row?.phonemessage,
        //name: row.name,
        serialnumber: data.id,
      },
    };

    return await API.del(apiName, path, myInit);
  }

  async function postAddContact() {
    const apiName = "ThermonovaAPI";
    const path = "/HP/contact";
    const myInit = {
      body: {
        email,
        phone,
        name,
        language,
        emailmessage: !!email,
        phonemessage: !!phone,
        serialnumber: data.id,
        createdAt: new Date().toUTCString(),
      },
    };

    return await API.put(apiName, path, myInit);
  }

  const handleAddServiceContact = () => {
    setPhone("");
    setEmail(serviceMail);
    setName("Service");
    setLanguage("DK");
    setServiceContact(true);
    setPressedAddService(true);
    };

  const handlePhonemessage = (id: string) => {
    setPhonemessage((prev) => {
      const d: { [key: string]: boolean } = { ...prev };
      d[id] = !d[id];
      return d;
    });
    const r = rows.find((i) => i.id === id);
    if (r) {
      updateContact(r, "phonemessage");
    }
  };

  const handleMailmessage = (id: string) => {
    setMailmessage((prev) => {
      const d = { ...prev };
      d[id] = !d[id];
      return d;
    });
    const r = rows.find((i) => i.id === id);

    if (r) {
      updateContact(r, "mailmessage");
    }
  };

  const updateContact = async (row: RowData, type: string) => {
    const apiName = "ThermonovaAPI";
    const path = "/HP/contact";
    const output: any[] = [];

    await API.patch(apiName, path, {
      body: {
        serialnumber: data.id,
        data: {
          email: row.email,
          phone: row.phone,
          name: row.name,
          language: row.language,
          mailmessage:
            type === "mailmessage" ? !row?.mailmessage : row?.mailmessage,
          phonemessage:
            type === "phonemessage" ? !row?.phonemessage : row?.phonemessage,
          serialnumber: data.id,
          id: row?.id,
        },
      },
    })
      .then((response) => {
        const r: RowData[] = response?.Contacts?.map((item: any) =>
          createData(item)
        );

        const m: { [key: string]: boolean } = {};
        const p: { [key: string]: boolean } = {};
        r?.forEach((item) => {
          m[item?.id] = item?.mailmessage;
          p[item?.id] = item?.phonemessage;
        });

        setMailmessage(m);
        setPhonemessage(p);

        setRows(r);
        setLoading(false);
        handleAPIResponse("updated");
      })
      .catch((error) => {
        console.log(error.response);
      });
    return output;
  };

  const clearAlert = () => {
    setTimeout(() => {
      setAPIalarm(0);
    }, 5000);
  };

  const createData = (element: any): RowData => {
    //console.log(element)
    return {
      email: element?.Mail || "",
      phone: element?.Phonenumber || "",
      name: element?.Name || "",
      language: element?.Language  || "",
      mailmessage: Boolean(element?.MailMessage),
      phonemessage: Boolean(element?.PhoneMessage),
      id: element?.AddedDateTime,
    };
  };

  async function getContactList() {
    const apiName = "ThermonovaAPI";
    const path = "/HP/contact";
    const output: RowData[] = [];

    await API.get(apiName, path, {
      queryStringParameters: {
        serialnumber: data.id,
      },
    })
      .then((response) => {
        if (response === false) {
        } else {
          response.Contacts.forEach((element: any) => {
            output.push(createData(element));
          });
        }
      })
      .catch((error) => {
        // console.log(error.response);
      });
    return output;
  }

  function getData() {
    getContactList().then((response) => {
      const m: { [key: string]: boolean } = {};
      const p: { [key: string]: boolean } = {};
      if(response.length === 0){
        setRows([])
      }

      response?.forEach((item) => {
        m[item?.id] = item?.mailmessage;
        p[item?.id] = item?.phonemessage;
      });
      response?.forEach((item) => {
        if(item?.email === serviceMail){
          setServiceContact(true);
        }
      });


      setMailmessage(m);
      setPhonemessage(p);
      
      if(response.length !== 0){
        setRows(response)
      }
      ;
    });
  }

  React.useEffect(() => {
    if (data?.id) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);


  React.useEffect(() => {
    
    if(pressedAddService === true){
      handleSend()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pressedAddService])
  

  const columns: GridColDef[] = [
    { field: "name", headerName: "Name", width: 180 },
    { field: "email", headerName: "Mail", width: 180 },
    {
      field: "phone",
      headerName: "Phonenumber",
      width: 170,
      align: "center",
    },
    {
      field: "mailmessage",
      headerName: "Mail message",
      width: 150,
      align: "center",
      renderCell: (cellValues) => {
        return (
          <Checkbox
            disabled={!cellValues.row.email}
            checked={mailmessage[cellValues.row.id]}
            onChange={() => handleMailmessage(cellValues.row.id)}
          />
        );
      },
    },
    {
      field: "phonemessage",
      headerName: "Phone message",
      width: 150,
      align: "center",
      renderCell: (cellValues) => {
        return (
          <Checkbox
            disabled={!cellValues.row.phone}
            checked={phonemessage[cellValues.row.id]}
            onChange={() => handlePhonemessage(cellValues.row.id)}
          />
        );
      },
    },
    {
      field: "language", headerName: "Language", width: 100, align: "center"
    },
    {
      field: "delete",
      headerName: "Delete",
      width: 150,
      align: "center",
      renderCell: (cellValues) => {
        return (
          <IconButton onClick={() => handleDelete(cellValues.row)}>
            <Delete />
          </IconButton>
        );
      },
    },
  ];



  const returnAlert = () => {
    switch (APIalarm) {
      case 1:
        return (
          <Alert severity="error">
            Contact already exists with that email or phone number
          </Alert>
        );
      case 2:
        return <Alert severity="success">Item deleted.</Alert>;
      case 3:
        return <Alert severity="success">Alarm list updated.</Alert>;
      default:
        return "";
    }
  };

  return {
    handleSend,
    returnAlert,
    loading,
    email,
    name,
    language,
    phone,
    handleChange,
    rows,
    columns,
    serviceContact,
    handleAddServiceContact
  };
};
