import React from "react";
import { API } from "aws-amplify";
import Alert from "@mui/material/Alert";

export const AddHPLogic = (handleClose: Function, updateHPList: Function) => {
  const [name, setName] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [city, setCity] = React.useState("");
  const [zipcode, setZipcode] = React.useState("");
  const [model, setModel] = React.useState("");
  const [serialnumber, setSerialnumber] = React.useState("");
  const [contactPerson, setContactPerson] = React.useState("");
  const [contactPersonPhone, setContactPersonPhone] = React.useState("");
  const [gwID, setGwID] = React.useState("");
  const [APIalarm, setAPIalarm] = React.useState(0);
  const [disabledSendButton, setDisabledSendButton] = React.useState(true);

  const handleSend = () => {
    postNewHPID()
      .then((response) => {
        handleAPIResponse(response);
      })
      .catch((error) => {
        console.log(error.response);
        handleAPIResponse("error");
      });
  };
  const handleClear = () => {
    setName("");
    setAddress("");
    setCity("");
    setZipcode("");
    setContactPerson("");
    setContactPersonPhone("");
    setModel("");
    setSerialnumber("");
  };
  const handleChange = (e: any) => {
    let switchValue;
    if (e.target.name === "model") {
      switchValue = e.target.name;
    } else {
      switchValue = e.target.id;
    }
    switch (switchValue) {
      case "serialnumber":
        setSerialnumber(e.target.value);
        break;
      case "name":
        setName(e.target.value);
        break;
      case "address":
        setAddress(e.target.value);
        break;
      case "city":
        setCity(e.target.value);
        break;
      case "zipcode":
        setZipcode(e.target.value);
        break;
      case "model":
        setModel(e.target.value);
        break;
      case "contactPerson":
        setContactPerson(e.target.value);
        break;
      case "contactPersonPhone":
        setContactPersonPhone(e.target.value);
        break;

      default:
        break;
    }
  };

  React.useEffect(() => {
    if (
      name !== "" &&
      address !== "" &&
      serialnumber !== "" &&
      city !== "" &&
      zipcode !== "" &&
      model !== ""
    ) {
      setDisabledSendButton(false);
    } else {
      setDisabledSendButton(true);
    }
  }, [name, address, serialnumber, city, zipcode, model]);

  const handleAPIResponse = (e: string) => {
    //console.log(e)
    if (e === "serialnumber exists") {
      setAPIalarm(1);
    } else if (e === "added") {
      setAPIalarm(2);
      handleClear();
      updateHPList();

      delay(1000).then(() => handleClose());
    }
  };

  const handleGWidChange = (e: any) => {
    setGwID(e.target.value);
  };

  async function postNewHPID() {
    const apiName = "ThermonovaAPI";
    const path = "/HP";
    const myInit = {
      // OPTIONAL
      body: {
        serialnumber: serialnumber,
        name: name,
        address: address,
        city: city,
        zipcode: zipcode,
        contactPerson: contactPerson,
        contactPersonPhone: contactPersonPhone,
        model: model,
        action: "newHP",
      }, // replace this with attributes you need
    };

    return await API.put(apiName, path, myInit);
  }
  function delay(time: number) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  const returnAlert = () => {
    switch (APIalarm) {
      case 1:
        return <Alert severity="error">Serialnumber exists.</Alert>;
      case 2:
        return <Alert severity="success">Heatpump added.</Alert>;
      default:
        return "";
    }
  };

  return {
    handleSend,
    gwID,
    handleGWidChange,
    returnAlert,
    serialnumber,
    name,
    address,
    city,
    zipcode,
    contactPerson,
    contactPersonPhone,
    model,
    handleChange,
    handleClear,
    disabledSendButton,
  };
};
