import Paper from "@mui/material/Paper";
import SettingsLogic from "./SettingsLogic";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { getRulesForModel } from "../../util/getRulesForModel";
import { Alert } from "@mui/material";

export default function SettingsView(props: any) {
  const {
    getCells,
    open,
    handleClose,
    dialogHeader,
    getChangeValueInput,
    getDialogAlert,
    getActionButtons,
  } = SettingsLogic(props);

  const rules = getRulesForModel(
    props.context["HPData"],
    props.Settings,
    props.context["hpAccessRules"]
  );

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle>{dialogHeader}</DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          {getChangeValueInput()}
        </DialogContent>
        {getActionButtons()}
        {getDialogAlert()}
      </Dialog>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Modbus Adress</TableCell>
              <TableCell>Name</TableCell>
              <TableCell align="right">Default</TableCell>
              <TableCell align="right">Value</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getCells({
              rules,
            })}
            {(!rules || props.context.errorFetchingHPAccessRules) && (
              <TableRow>
                <TableCell colSpan={5}>
                  <Alert severity="warning">
                    You do not have access to this settings
                  </Alert>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
