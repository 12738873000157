import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Gateway, Software } from "../types";

interface GT {
  gateways: { [id: string]: Gateway };
  softwares: { [id: string]: Software };
}

const initialState: GT = {
  gateways: {},
  softwares: {},
};

export const gatewaySlice = createSlice({
  name: "gateway",
  initialState,
  reducers: {
    setGateways: (state, action: PayloadAction<{ [id: string]: Gateway }>) => {
      state.gateways = action.payload;
    },
    addGateway: (state, action: PayloadAction<Gateway>) => {
      state.gateways[action.payload.id] = action.payload;
    },
    removeGateway: (state, action: PayloadAction<string>) => {
      delete state.gateways[action.payload];
    },
    setSoftwares: (
      state,
      action: PayloadAction<{ [id: string]: Software }>
    ) => {
      state.softwares = action.payload;
    },
    addSoftware: (state, action: PayloadAction<Software>) => {
      state.softwares[action.payload.id] = action.payload;
    },
  },
});

export const {
  setGateways,
  addGateway,
  removeGateway,
  setSoftwares,
  addSoftware,
} = gatewaySlice.actions;

export default gatewaySlice.reducer;
