export const MasterDefaultView = {
  chartDataset: {
    MS: [],
    MH: [
      {
        8151: {
          color: "#9c9ee2",
        },
      },
      {
        8104: {
          color: "#000eee",
        },
      },
      {
        8102: {
          color: "#fc0049",
        },
      },
      {
        8365: {
          color: "#b97100",
        },
      },
      {
        8341: {
          color: "#cf008d",
        },
      },
      {
        8103: {
          color: "#83bb00",
        },
      },
      {
        8171: {
          color: "#cd0042",
        },
      },
      {
        8173: {
          color: "#9ec600",
        },
      },
      {
        8242: {
          color: "#00b284",
        },
      },
      {
        8244: {
          color: "#176e82",
        },
      },
      {
        8115: {
          color: "#000bbe",
        },
      },
      {
        8272: {
          color: "#7842b7",
        },
      },
      {
        8119: {
          color: "#590000",
        },
      },
      {
        8123: {
          color: "#a400a4",
        },
      },
      {
        8125: {
          color: "#c15522",
        },
      },
      {
        8127: {
          color: "#006f16",
        },
      },
      {
        8129: {
          color: "#7d4100",
        },
      },
      {
        8157: {
          color: "#b57b17",
        },
      },
      {
        8159: {
          color: "#7eea85",
        },
      },
      {
        8161: {
          color: "#e88fc1",
        },
      },
      {
        8163: {
          color: "#43d339",
        },
      },
      {
        8153: {
          color: "#98af21",
        },
      },
      {
        8350: {
          color: "#ba3fea",
        },
      },
      {
        8352: {
          color: "#77a9d8",
        },
      },
      {
        8344: {
          color: "#b3fcae",
        },
      },
      {
        8346: {
          color: "#009f57",
        },
      },
      {
        8361: {
          color: "#97bae5",
        },
      },
      {
        8105: {
          color: "#b600a7",
        },
      },
      {
        8107: {
          color: "#c41117",
        },
      },
      {
        8109: {
          color: "#aa222f",
        },
      },
      {
        8363: {
          color: "#f4d63f",
        },
      },
    ],
    S1S: [],
    S1H: [],
    S2S: [],
    S2H: [],
    S3S: [],
    S3H: [],
    S4S: [],
    S4H: [],
  },
  liveDataset: [
    {
      id: "MH8151",
      name: "Alarm",
      adr: "8151",
      color: "#9c9ee2",
      controller: "MH",
    },
    {
      id: "MH8104",
      name: "Outdoor temp",
      adr: "8104",
      color: "#000eee",
      controller: "MH",
    },
    {
      id: "MH8102",
      name: "Fremløb temp",
      adr: "8102",
      color: "#fc0049",
      controller: "MH",
    },
    {
      id: "MH8365",
      name: "Return heat system temp",
      adr: "8365",
      color: "#b97100",
      controller: "MH",
    },
    {
      id: "MH8341",
      name: "Heatpump forward temp",
      adr: "8341",
      color: "#cf008d",
      controller: "MH",
    },
    {
      id: "MH8103",
      name: "Heatpump return temp",
      adr: "8103",
      color: "#83bb00",
      controller: "MH",
    },
    {
      id: "MH8171",
      name: "EM Return temp",
      adr: "8171",
      color: "#cd0042",
      controller: "MH",
    },
    {
      id: "MH8173",
      name: "EM Forward temp",
      adr: "8173",
      color: "#9ec600",
      controller: "MH",
    },
    {
      id: "MH8242",
      name: "EM Flow lh",
      adr: "8242",
      color: "#00b284",
      controller: "MH",
    },
    {
      id: "MH8244",
      name: "EM kW",
      adr: "8244",
      color: "#176e82",
      controller: "MH",
    },
    {
      id: "MH8115",
      name: "CP speed %",
      adr: "8115",
      color: "#000bbe",
      controller: "MH",
    },
    {
      id: "MH8272",
      name: "COP",
      adr: "8272",
      color: "#7842b7",
      controller: "MH",
    },
    {
      id: "MH8119",
      name: "Requested temp",
      adr: "8119",
      color: "#590000",
      controller: "MH",
    },
    {
      id: "MH8123",
      name: "MOD 1 cond temp",
      adr: "8123",
      color: "#a400a4",
      controller: "MH",
    },
    {
      id: "MH8125",
      name: "MOD 2 cond temp",
      adr: "8125",
      color: "#c15522",
      controller: "MH",
    },
    {
      id: "MH8127",
      name: "MOD 3 cond temp",
      adr: "8127",
      color: "#006f16",
      controller: "MH",
    },
    {
      id: "MH8129",
      name: "MOD 4 cond temp",
      adr: "8129",
      color: "#7d4100",
      controller: "MH",
    },
    {
      id: "MH8157",
      name: "MOD 1 active",
      adr: "8157",
      color: "#b57b17",
      controller: "MH",
    },
    {
      id: "MH8159",
      name: "MOD 2 active",
      adr: "8159",
      color: "#7eea85",
      controller: "MH",
    },
    {
      id: "MH8161",
      name: "MOD 3 active",
      adr: "8161",
      color: "#e88fc1",
      controller: "MH",
    },
    {
      id: "MH8163",
      name: "MOD 4 active",
      adr: "8163",
      color: "#43d339",
      controller: "MH",
    },
    {
      id: "MH8153",
      name: "Supply heater 1 active",
      adr: "8153",
      color: "#98af21",
      controller: "MH",
    },
    {
      id: "MH8350",
      name: "SUP heater 2 active",
      adr: "8350",
      color: "#ba3fea",
      controller: "MH",
    },
    {
      id: "MH8352",
      name: "SUP heater 3 active",
      adr: "8352",
      color: "#77a9d8",
      controller: "MH",
    },
    {
      id: "MH8344",
      name: "SUP heater shunt %",
      adr: "8344",
      color: "#b3fcae",
      controller: "MH",
    },
    {
      id: "MH8346",
      name: "Buffertank temp",
      adr: "8346",
      color: "#009f57",
      controller: "MH",
    },
    {
      id: "MH8361",
      name: "Domestic water active",
      adr: "8361",
      color: "#97bae5",
      controller: "MH",
    },
    {
      id: "MH8105",
      name: "Domestic water temp",
      adr: "8105",
      color: "#b600a7",
      controller: "MH",
    },
    {
      id: "MH8107",
      name: "Pressure before water filter bar",
      adr: "8107",
      color: "#c41117",
      controller: "MH",
    },
    {
      id: "MH8109",
      name: "Water filter pressure after bar",
      adr: "8109",
      color: "#aa222f",
      controller: "MH",
    },
    {
      id: "MH8363",
      name: "Pressure drop water filter bar",
      adr: "8363",
      color: "#f4d63f",
      controller: "MH",
    },
  ],
  disabledLineGraphs: [
    {
      controller: "MH",
      adr: "8363",
    },
    {
      controller: "MH",
      adr: "8109",
    },
    {
      controller: "MH",
      adr: "8107",
    },
    {
      controller: "MH",
      adr: "8151",
    },
    {
      controller: "MH",
      adr: "8157",
    },
    {
      controller: "MH",
      adr: "8159",
    },
    {
      controller: "MH",
      adr: "8161",
    },
    {
      controller: "MH",
      adr: "8163",
    },
    {
      controller: "MH",
      adr: "8153",
    },
    {
      controller: "MH",
      adr: "8350",
    },
    {
      controller: "MH",
      adr: "8352",
    },
    {
      controller: "MH",
      adr: "8361",
    },
  ],
};

export const Modul1DefaultView = {
  chartDataset: {
    MS: [],
    MH: [],
    S1S: [],
    S1H: [
      { 8152: { color: "#cc0079" } },
      { 8151: { color: "#0044ab" } },
      { 8110: { color: "#bc04f4" } },
      { 8111: { color: "#35005e" } },
      { 8106: { color: "#00668c" } },
      { 8142: { color: "#df0000" } },
      { 8128: { color: "#66dd16" } },
      { 8143: { color: "#00b65a" } },
      { 8144: { color: "#005c3e" } },
      { 8130: { color: "#9a5000" } },
      { 8116: { color: "#008b23" } },
      { 8169: { color: "#00724d" } },
      { 8171: { color: "#00588f" } },
      { 8317: { color: "#ffb5f6" } },
    ],
    S2S: [],
    S2H: [],
    S3S: [],
    S3H: [],
    S4S: [],
    S4H: [],
  },
  liveDataset: [
    {
      id: "S1H8152",
      name: "M1 HP temp",
      adr: "8152",
      color: "#cc0079",
      controller: "S1H",
    },
    {
      id: "S1H8151",
      name: "M1 LP temp ",
      adr: "8151",
      color: "#0044ab",
      controller: "S1H",
    },
    {
      id: "S1H8110",
      name: "M1 SH",
      adr: "8110",
      color: "#bc04f4",
      controller: "S1H",
    },
    {
      id: "S1H8111",
      name: "M1 SH Ref",
      adr: "8111",
      color: "#35005e",
      controller: "S1H",
    },
    {
      id: "S1H8106",
      name: "M1 Stepper actual pos %",
      adr: "8106",
      color: "#00668c",
      controller: "S1H",
    },
    {
      id: "S1H8142",
      name: "M1 Hotgas temp",
      adr: "8142",
      color: "#df0000",
      controller: "S1H",
    },
    {
      id: "S1H8128",
      name: "M1 Defrost timer",
      adr: "8128",
      color: "#66dd16",
      controller: "S1H",
    },
    {
      id: "S1H8143",
      name: "M1 Defrost evap 1 temp",
      adr: "8143",
      color: "#00b65a",
      controller: "S1H",
    },
    {
      id: "S1H8144",
      name: "M1 Defrost evap 2 temp",
      adr: "8144",
      color: "#005c3e",
      controller: "S1H",
    },
    {
      id: "S1H8130",
      name: "M1 Defrost active",
      adr: "8130",
      color: "#9a5000",
      controller: "S1H",
    },
    {
      id: "S1H8116",
      name: "M1 Fan speed V",
      adr: "8116",
      color: "#008b23",
      controller: "S1H",
    },
    {
      id: "S1H8169",
      name: "M1 FC kW",
      adr: "8169",
      color: "#00724d",
      controller: "S1H",
    },
    {
      id: "S1H8171",
      name: "M1 FC Hz",
      adr: "8171",
      color: "#00588f",
      controller: "S1H",
    },
    {
      id: "S1H8317",
      name: "M1 FC safe stop",
      adr: "8317",
      color: "#ffb5f6",
      controller: "S1H",
    },
  ],
  disabledLineGraphs: [
    { controller: "MH", adr: "8363" },
    { controller: "MH", adr: "8109" },
    { controller: "MH", adr: "8107" },
    { controller: "MH", adr: "8151" },
    { controller: "MH", adr: "8157" },
    { controller: "MH", adr: "8159" },
    { controller: "MH", adr: "8161" },
    { controller: "MH", adr: "8163" },
    { controller: "MH", adr: "8153" },
    { controller: "MH", adr: "8350" },
    { controller: "MH", adr: "8352" },
    { controller: "MH", adr: "8361" },
    { controller: "S1H", adr: "8317" },
    { controller: "S1H", adr: "8130" },
  ],
};

export const Modul2DefaultView = {
  chartDataset: {
    MS: [],
    MH: [],
    S1S: [],
    S1H: [],
    S2S: [],
    S2H: [
      { 8239: { color: "#cc0079" } },
      { 8238: { color: "#0044ab" } },
      { 8112: { color: "#bc04f4" } },
      { 8113: { color: "#35005e" } },
      { 8108: { color: "#00668c" } },
      { 8223: { color: "#df0000" } },
      { 8213: { color: "#66dd16" } },
      { 8225: { color: "#00b65a" } },
      { 8227: { color: "#005c3e" } },
      { 8215: { color: "#9a5000" } },
      { 8118: { color: "#008b23" } },
      { 8244: { color: "#00724d" } },
      { 8246: { color: "#00588f" } },
      { 8318: { color: "#ffb5f6" } },
    ],
    S3S: [],
    S3H: [],
    S4S: [],
    S4H: [],
  },
  liveDataset: [
    {
      id: "S2H8239",
      name: "M2 HP temp ",
      adr: "8239",
      color: "#cc0079",
      controller: "S2H",
    },
    {
      id: "S2H8238",
      name: "M2 LP temp ",
      adr: "8238",
      color: "#0044ab",
      controller: "S2H",
    },
    {
      id: "S2H8112",
      name: "M2 SH",
      adr: "8112",
      color: "#bc04f4",
      controller: "S2H",
    },
    {
      id: "S2H8113",
      name: "M2 SH ref",
      adr: "8113",
      color: "#35005e",
      controller: "S2H",
    },
    {
      id: "S2H8108",
      name: "M2 Stepper actual pos %",
      adr: "8108",
      color: "#00668c",
      controller: "S2H",
    },
    {
      id: "S2H8223",
      name: "M2 Hotgas temp",
      adr: "8223",
      color: "#df0000",
      controller: "S2H",
    },
    {
      id: "S2H8213",
      name: "M2 Defrost timer",
      adr: "8213",
      color: "#66dd16",
      controller: "S2H",
    },
    {
      id: "S2H8225",
      name: "M2 Defrost evap 1 temp",
      adr: "8225",
      color: "#00b65a",
      controller: "S2H",
    },
    {
      id: "S2H8227",
      name: "M2 Defrost evap 2 temp",
      adr: "8227",
      color: "#005c3e",
      controller: "S2H",
    },
    {
      id: "S2H8215",
      name: "M2 Defrost active",
      adr: "8215",
      color: "#9a5000",
      controller: "S2H",
    },
    {
      id: "S2H8118",
      name: "M2 Fan speed V",
      adr: "8118",
      color: "#008b23",
      controller: "S2H",
    },
    {
      id: "S2H8244",
      name: "M2 FC kW",
      adr: "8244",
      color: "#00724d",
      controller: "S2H",
    },
    {
      id: "S2H8246",
      name: "M2 FC Hz",
      adr: "8246",
      color: "#00588f",
      controller: "S2H",
    },
    {
      id: "S2H8318",
      name: "M2 FC safe stop",
      adr: "8318",
      color: "#ffb5f6",
      controller: "S2H",
    },
  ],
  disabledLineGraphs: [
    { controller: "S2H", adr: "8318" },
    { controller: "S2H", adr: "8215" },
    { controller: "S2H", adr: "8213" }
  ],
};

export const Modul3DefaultView = {
  chartDataset: {
    MS: [],
    MH: [],
    S1S: [],
    S1H: [],
    S2S: [],
    S2H: [],
    S3S: [],
    S3H: [
      { 8152: { color: "#cc0079" } },
      { 8151: { color: "#0044ab" } },
      { 8110: { color: "#bc04f4" } },
      { 8111: { color: "#35005e" } },
      { 8106: { color: "#00668c" } },
      { 8142: { color: "#df0000" } },
      { 8128: { color: "#66dd16" } },
      { 8143: { color: "#00b65a" } },
      { 8144: { color: "#005c3e" } },
      { 8130: { color: "#9a5000" } },
      { 8116: { color: "#008b23" } },
      { 8169: { color: "#00724d" } },
      { 8171: { color: "#00588f" } },
      { 8317: { color: "#ffb5f6" } },
    ],
    S4S: [],
    S4H: [],
  },
  liveDataset: [
    {
      id: "S3H8152",
      name: "M1 HP temp",
      adr: "8152",
      color: "#cc0079",
      controller: "S3H",
    },
    {
      id: "S3H8151",
      name: "M1 LP temp ",
      adr: "8151",
      color: "#0044ab",
      controller: "S3H",
    },
    {
      id: "S3H8110",
      name: "M1 SH",
      adr: "8110",
      color: "#bc04f4",
      controller: "S3H",
    },
    {
      id: "S3H8111",
      name: "M1 SH Ref",
      adr: "8111",
      color: "#35005e",
      controller: "S3H",
    },
    {
      id: "S3H8106",
      name: "M1 Stepper actual pos %",
      adr: "8106",
      color: "#00668c",
      controller: "S3H",
    },
    {
      id: "S3H8142",
      name: "M1 Hotgas temp",
      adr: "8142",
      color: "#df0000",
      controller: "S3H",
    },
    {
      id: "S3H8128",
      name: "M1 Defrost timer",
      adr: "8128",
      color: "#66dd16",
      controller: "S3H",
    },
    {
      id: "S3H8143",
      name: "M1 Defrost evap 1 temp",
      adr: "8143",
      color: "#00b65a",
      controller: "S3H",
    },
    {
      id: "S3H8144",
      name: "M1 Defrost evap 2 temp",
      adr: "8144",
      color: "#005c3e",
      controller: "S3H",
    },
    {
      id: "S3H8130",
      name: "M1 Defrost active",
      adr: "8130",
      color: "#9a5000",
      controller: "S3H",
    },
    {
      id: "S3H8116",
      name: "M1 Fan speed V",
      adr: "8116",
      color: "#008b23",
      controller: "S3H",
    },
    {
      id: "S3H8169",
      name: "M1 FC kW",
      adr: "8169",
      color: "#00724d",
      controller: "S3H",
    },
    {
      id: "S3H8171",
      name: "M1 FC Hz",
      adr: "8171",
      color: "#00588f",
      controller: "S3H",
    },
    {
      id: "S3H8317",
      name: "M1 FC safe stop",
      adr: "8317",
      color: "#ffb5f6",
      controller: "S3H",
    },
  ],
  disabledLineGraphs: [
    { controller: "S3H", adr: "8317" },
    { controller: "S3H", adr: "8130" },
    { controller: "S3H", adr: "8128" }
  ],
};

export const Modul4DefaultView = {
    chartDataset: {
      MS: [],
      MH: [],
      S1S: [],
      S1H: [],
      S2S: [],
      S2H: [],
      S3S: [],
      S3H: [],
      S4S: [],
      S4H: [
        { 8239: { color: "#cc0079" } },
        { 8238: { color: "#0044ab" } },
        { 8112: { color: "#bc04f4" } },
        { 8113: { color: "#35005e" } },
        { 8108: { color: "#00668c" } },
        { 8223: { color: "#df0000" } },
        { 8213: { color: "#66dd16" } },
        { 8225: { color: "#00b65a" } },
        { 8227: { color: "#005c3e" } },
        { 8215: { color: "#9a5000" } },
        { 8118: { color: "#008b23" } },
        { 8244: { color: "#00724d" } },
        { 8246: { color: "#00588f" } },
        { 8318: { color: "#ffb5f6" } },
      ],
    },
    liveDataset: [
      {
        id: "S4H8239",
        name: "M2 HP temp ",
        adr: "8239",
        color: "#cc0079",
        controller: "S4H",
      },
      {
        id: "S4H8238",
        name: "M2 LP temp ",
        adr: "8238",
        color: "#0044ab",
        controller: "S4H",
      },
      {
        id: "S4H8112",
        name: "M2 SH",
        adr: "8112",
        color: "#bc04f4",
        controller: "S4H",
      },
      {
        id: "S4H8113",
        name: "M2 SH ref",
        adr: "8113",
        color: "#35005e",
        controller: "S4H",
      },
      {
        id: "S4H8108",
        name: "M2 Stepper actual pos %",
        adr: "8108",
        color: "#00668c",
        controller: "S4H",
      },
      {
        id: "S4H8223",
        name: "M2 Hotgas temp",
        adr: "8223",
        color: "#df0000",
        controller: "S4H",
      },
      {
        id: "S4H8213",
        name: "M2 Defrost timer",
        adr: "8213",
        color: "#66dd16",
        controller: "S4H",
      },
      {
        id: "S4H8225",
        name: "M2 Defrost evap 1 temp",
        adr: "8225",
        color: "#00b65a",
        controller: "S4H",
      },
      {
        id: "S4H8227",
        name: "M2 Defrost evap 2 temp",
        adr: "8227",
        color: "#005c3e",
        controller: "S4H",
      },
      {
        id: "S4H8215",
        name: "M2 Defrost active",
        adr: "8215",
        color: "#9a5000",
        controller: "S4H",
      },
      {
        id: "S4H8118",
        name: "M2 Fan speed V",
        adr: "8118",
        color: "#008b23",
        controller: "S4H",
      },
      {
        id: "S4H8244",
        name: "M2 FC kW",
        adr: "8244",
        color: "#00724d",
        controller: "S4H",
      },
      {
        id: "S4H8246",
        name: "M2 FC Hz",
        adr: "8246",
        color: "#00588f",
        controller: "S4H",
      },
      {
        id: "S4H8318",
        name: "M2 FC safe stop",
        adr: "8318",
        color: "#ffb5f6",
        controller: "S4H",
      },
    ],
    disabledLineGraphs: [
      { controller: "S4H", adr: "8318" },
      { controller: "S4H", adr: "8215" },
      { controller: "S4H", adr: "8213" }
    ],
  };
