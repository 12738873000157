import {
  Navigate,
  Route,
  Routes,
  Outlet,
  useLocation,
  Link,
} from "react-router-dom";
import { OrgRouter } from "./OrgRouter";
// import { RolesRouter } from "./RolesRouter";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useMemo } from "react";

function a11yProps(index: string) {
  return {
    id: `access-management-tab-${index}`,
    "aria-controls": `access-management-tabpanel-${index}`,
  };
}

const allPaths = [
  {
    path: "/access-management/company",
    label: "Companies",
  },
];

function RootLayout() {
  const { pathname } = useLocation();
  const currentPath = useMemo(() => {
    return pathname
      .split("/")
      .filter((part, index) => index <= 2)
      .join("/");
  }, [pathname]);
  return (
    <Box>
      <Tabs aria-label="basic tabs example" value={currentPath}>
        {allPaths.map((path) => {
          return (
            <Tab
              key={path.path}
              label={path.label}
              {...a11yProps(path.path)}
              component={Link}
              value={path.path}
              to={path.path}
            />
          );
        })}
      </Tabs>
      <div style={{ paddingTop: 20 }}>
        <Outlet />
      </div>
    </Box>
  );
}

export function AccessManagementRouter() {
  return (
    <Routes>
      <Route path="access-management/*" element={<RootLayout />}>
        <Route path="company/*" element={<OrgRouter />} />
        {/* <Route path="roles/*" element={<RolesRouter />} /> */}
        <Route index element={<Navigate to="company" />} />
      </Route>
    </Routes>
  );
}
