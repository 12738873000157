import { Breakpoint, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import MenuIcon from "@mui/icons-material/Menu";
import Divider from "@mui/material/Divider";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

import List from "@mui/material/List";
import Container from "@mui/material/Container";
import Copyright from "../../Copyright";
import NavLogic from "../../Navigation/NavLogic";
import NavigationLogic from "./NavigationLogic";
import NovaLogic from "./NovaLogic";
import NavButtonView from "../Nav/NavButtonView";

export default function NovaView(props: any) {
  const HPData = props.HPData;
  const { webWorker } = NovaLogic(HPData);
  const { mdTheme, Drawer, AppBar, open, toggleDrawer } = NavLogic();
  const { navClickHandler, contentSize, contentView, selectedMenu } =
    NavigationLogic(HPData, webWorker, props.context);

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute">
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "flex" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            {/* <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
              className={"Header"}
            >
              {headerTitle}
            </Typography> */}
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List>
            <div>
              <NavButtonView
                navClickHandler={navClickHandler}
                selectedMenu={selectedMenu}
              />
            </div>
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth={contentSize as Breakpoint} sx={{ mt: 4, mb: 4 }}>
            {contentView}
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
