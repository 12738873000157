import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { API } from "aws-amplify";
import { setHeatpumps, setFilteredHeatpumps } from "../Redux/MainStore";

export const MainViewDataHandler = () => {
  const Dispatch = useDispatch();
  const heatpumps = useSelector((state: any) => state.mainData.heatpumps);
  const heatpumpsFilterText = useSelector(
    (state: any) => state.mainData.heatpumpsFilterText
  );

  function createData(
    Heatpump: string,
    Model: string,
    Serialnumber: string,
    Address: string,
    City: string,
    Zipcode: string,
    GatewayID: string,
    LastDataUpdate: string,
    CreatedWhen: string,
    ContactPerson: string,
    ContactPersonPhone: string,
    OnlineTimeStamp: string,
    SimID: string,
    AlarmActive: string
  ) {
    var date = new Date(LastDataUpdate).toLocaleDateString();
    return {
      id: Serialnumber,
      col1: Heatpump,
      col2: Model,
      col3: Serialnumber,
      col4: Address,
      col5: City,
      col6: Zipcode,
      col7: GatewayID,
      col8: date.toString(),
      col9: CreatedWhen,
      col10: ContactPerson,
      col11: ContactPersonPhone,
      col13: OnlineTimeStamp,
      col14: SimID,
      alarm: AlarmActive,
    };
  }

  async function getHPData() {
    const apiName = "ThermonovaAPI";
    const path = "/heatpumps";
    const output: any[] = [];
    await API.get(apiName, path, {})
      .then((response) => {
        response?.forEach((element: any) => {
          output.push(
            createData(
              element.Name,
              element.Model,
              element.Serialnumber,
              element.Address,
              element.City,
              element.Postcode,
              element.GatewayID,
              element.LastDataUpdate,
              element.CreatedWhen,
              element.ContactPerson,
              element.ContactPersonPhone,
              element.OnlineTimeStamp,
              element.SimID,
              element.AlarmActive
            )
          );
        });
      })
      .catch((error) => {
        // console.log(error.response);
      });
    return output;
  }

  function getData() {
    getHPData().then((response) => {
      if (response?.length > 0) {
        //console.log(response)
        //console.log(response)
        Dispatch(setHeatpumps(response));
        Dispatch(setFilteredHeatpumps(response));
        //Dispatch(setHeatpumpsFilterText(heatpumpsFilterText));
        //console.log("get heatpumps")
        //setFullRows(sorted);
        //setRows(sorted);
      }
    });
  }
  React.useEffect(() => {
    //console.log("useeffect")

    getData();

    const interval = setInterval(() => {
      //console.log("reload hp")
      getData();
    }, 300000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    function escapeRegExp(value: string) {
      return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
    }
    const searchRegex = new RegExp(escapeRegExp(heatpumpsFilterText), "i");
    const filteredRows = heatpumps.filter((row: any) => {
      return Object.keys(row).some((field: any) => {
        return row[field] && searchRegex.test(row[field].toString());
      });
    });
    Dispatch(setFilteredHeatpumps(filteredRows));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [heatpumpsFilterText]);

  return { getData };
};
