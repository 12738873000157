import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setGraphDate,
  setXmin,
  setXnextUpdate,
  setXmax,
  changeActualValues,
} from "../Redux/HP";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";

export default function LineChartsLogic(props: any) {
  const {
    graphData,
    disabledLineGraphs,
    xmin,
    xmax,
    ymin,
    ymax,
    xsteps,
    ysteps,
    liveData,
    xnextUpdate,
    xDuration,
  } = useSelector((state: any) => state.heatpump);
  const [chartWidth, setChartWidth] = React.useState(0);
  const vh = Math.max(
    document.documentElement.clientHeight || 0,
    window.innerHeight || 0
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    setChartWidth(
      props.sizeRef
      
    );
    
  }, [props.sizeRef]);

  React.useEffect(() => {
    dispatch(setGraphDate(new Date(xmin).toISOString().split("T")[0]));
  }, [xmin, dispatch]);

  React.useEffect(() => {
    if (liveData && xnextUpdate <= Date.now()) {
      var date = Date.now();
      dispatch(setXmin(date - xDuration / 2));
      dispatch(setXnextUpdate(date + (xDuration / 2 - 60000)));
      dispatch(setXmax(date + xDuration / 2));
    }
    if (liveData) {
      graphData.forEach((element: any) => {
        dispatch(
          changeActualValues({
            name: element.controller + element.name,
            value: element.actualValue,
          })
        );
      });
    }
  }, [dispatch, graphData, xnextUpdate, liveData, xDuration]);

  const generateXticks = () => {
    var dif = xmax - xmin;
    var i = dif / xsteps;
    var out = [];
    var counter = 0;
    while (counter <= i) {
      out.push(xmin + counter * xsteps);
      counter = counter + 1;
    }
    return out;
  };
  const generateYticks = () => {
    var dif = ymax - ymin;
    var i = dif / ysteps;
    var out = [];
    var counter = 0;
    while (counter <= i) {
      out.push(ymin + counter * ysteps);
      counter = counter + 1;
    }
    return out;
  };

  type CustomTooltipProps = {
    active?: boolean;
    payload?: any;
    label?: string;
  };

  const CustomTooltip = ({ active, payload, label }: CustomTooltipProps) => {
    if (active && payload && payload.length) {
      return (
        <Card sx={{ minWidth: 275 }}>
          <CardHeader title={new Date(label || "").toLocaleString()} />

          <CardContent>
            {payload.map((s: any) => {
              var index = payload.indexOf(s);
              return (
                <Typography
                  key={index}
                  variant="body1"
                  style={{ color: s.color }}
                >
                  {`${s.name} : ${s.value}`}
                </Typography>
              );
            })}
          </CardContent>
        </Card>
      );
    }

    return null;
  };

  return {
    graphData,
    chartWidth,
    CustomTooltip,
    disabledLineGraphs,
    vh,
    xmin,
    xmax,
    ymin,
    ymax,
    generateXticks,
    generateYticks,
  };
}
