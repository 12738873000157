import BMF from "browser-md5-file";
import { useDispatch } from "react-redux";
import { setFileToUpload, setFileChecksum, setFileChunks } from "../Redux/HP";

export default function ChooseFileLogic() {
  const bmf = new BMF();
  const dispatch = useDispatch();

  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      //console.log(reader.result.replace('data:application/octet-stream;base64,',''))
      cb(reader.result?.replace("data:application/octet-stream;base64,", ""));
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const chunkString = (str, length) => {
    return str.match(new RegExp(".{1," + length + "}", "g"));
  };

  const handleChooseFile = (e) => {
    dispatch(setFileToUpload(e?.target?.files?.[0].name));
    bmf.md5(e?.target?.files?.[0], (err, md5) => {
      //console.log('err:', err);
      //console.log('md5 string:', md5); // 97027eb624f85892c69c4bcec8ab0f11
      dispatch(setFileChecksum(md5));
    });
    getBase64(e.target.files?.[0], (_file) => {
      //setFileSize(_file.size)

      var fileBlobs = chunkString(_file, 60000);
      dispatch(setFileChunks(fileBlobs));
      //console.log(fileBlobs)
    });
  };
  return { handleChooseFile };
}
