import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import useSWR from "swr";
import { API } from "aws-amplify";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import { Outlet, useNavigate } from "react-router-dom";
import { OrgGrid } from "./components/OrgGrid";
import { Route, Routes } from "react-router-dom";
import AddOrganization from "./AddOrganization";
import ManageOrganization from "./ManageOrganization";
import AssignHP from "./AssignHP";
import RemoveOrganization from "./RemoveOrganization";
import { useAuthorizer } from "../util/authorizer";

const fetcher = () => {
  const apiName = "ThermonovaAPI";
  const path = "/organization/list";
  return API.get(apiName, path, {});
};

function Index() {
  const { data, error, isLoading } = useSWR("organizations", fetcher);
  const navigate = useNavigate();
  const { isAdministrator } = useAuthorizer();
  return (
    <Paper sx={{ width: "100%", overflow: "hidden", position: "relative" }}>
      {isAdministrator && (
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-start"
          spacing={2}
        >
          <Box component="section" sx={{ p: 2 }}>
            <Tooltip title="Add Company" placement="top">
              <Button
                variant="contained"
                onClick={() => {
                  navigate("/access-management/company/add");
                }}
              >
                <AddIcon style={{ fontSize: 20, marginRight: 5 }} />
                Add Company
              </Button>
            </Tooltip>
          </Box>
        </Stack>
      )}
      <Box sx={{ height: "80vh" }}>
        <OrgGrid
          data={data}
          onActionClick={(action, context) => {
            if (action === "add-sub-organization") {
              navigate(
                "/access-management/company/add?parent=" +
                  encodeURIComponent(context.row.id)
              );
            } else if (action === "manage") {
              navigate(
                "/access-management/company/manage?organization=" +
                  encodeURIComponent(context.row.id)
              );
            } else if (action === "assign-heatpumps") {
              navigate(
                "/access-management/company/assign-hp?organization=" +
                  encodeURIComponent(context.row.id)
              );
            } else if (action === "remove") {
              navigate(
                "/access-management/company/remove?organization=" +
                  encodeURIComponent(context.row.id)
              );
            }
          }}
          isLoading={isLoading}
          error={error}
        />
      </Box>
      <Outlet />
    </Paper>
  );
}

export function OrgRouter() {
  return (
    <Routes>
      <Route path="" element={<Index />}>
        <Route path="add" element={<AddOrganization />} />
        <Route path="manage" element={<ManageOrganization />} />
        <Route path="assign-hp" element={<AssignHP />} />
        <Route path="remove" element={<RemoveOrganization />} />
      </Route>
    </Routes>
  );
}
