import React from 'react'

import Button from '@mui/material/Button';

import FormControl from '@mui/material/FormControl';

//import { v4 as uuidv4 } from 'uuid';

import ChooseFileLogic from './ChooseFileLogic'

export default function ChooseFile() {
const {handleChooseFile} = ChooseFileLogic()



  return (
    <FormControl fullWidth>
        <input
            accept=".pk"
            //className={classes.input}
            style={{ display: 'none' }}
            id="raised-button-file"
            type="file"
            hidden
            onInput={handleChooseFile}
        />
        <label htmlFor="raised-button-file">
            <Button variant="contained" component="span"  >
                Choose software
            </Button>
        </label>
    </FormControl>
        
                        
                    
  )
}
