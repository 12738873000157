import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import useSWR from "swr";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import { Outlet, useNavigate } from "react-router-dom";
import { Table } from "./Table";
import AddManageRule from "./AddManageRule";
import { Route, Routes } from "react-router-dom";
import { roleTemplatesFetcher } from "../util/fetcher";
import RemoveRole from "../AccessManagement/RemoveRole";

export function Index() {
  const { key, fetcher } = roleTemplatesFetcher();
  const { data, error, isLoading } = useSWR(key, fetcher);
  const navigate = useNavigate();
  return (
    <Paper sx={{ width: "100%", overflow: "hidden", position: "relative" }}>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-start"
        spacing={2}
      >
        <Box component="section" sx={{ p: 2 }}>
          <Tooltip title="Add Company" placement="top">
            <Button
              variant="contained"
              onClick={() => {
                navigate("/role-management/index/add");
              }}
            >
              <AddIcon style={{ fontSize: 20, marginRight: 5 }} />
              Add Role Template
            </Button>
          </Tooltip>
        </Box>
      </Stack>
      <Box sx={{ height: "80vh" }}>
        <Table
          data={data}
          onActionClick={(action, context) => {
            if (action === "manage") {
              navigate(
                "/role-management/index/manage?id=" +
                  encodeURIComponent(context.row.sk)
              );
            } else if (action === "remove") {
              navigate(
                "/role-management/index/remove?id=" +
                  encodeURIComponent(context.row.sk)
              );
            }
          }}
          isLoading={isLoading}
          error={error}
        />
      </Box>
      <Outlet />
    </Paper>
  );
}

export function RoleManagementRouter() {
  return (
    <Routes>
      <Route path="" element={<Index />}>
        <Route path="add" element={<AddManageRule />} />
        <Route path="manage" element={<AddManageRule />} />
        <Route path="remove" element={<RemoveRole />} />
      </Route>
    </Routes>
  );
}
