import { API } from "aws-amplify";

export const roleTemplatesFetcher = () => {
  const fetcher = () => {
    const apiName = "ThermonovaAPI";
    const path = "/role/list/template";
    return API.get(apiName, path, {});
  };
  return {
    fetcher,
    key: "roleTemplates",
  };
};
