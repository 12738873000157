import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { PubSub } from "aws-amplify";
import { setFileToUpload, setFileChecksum, setFileChunks } from "../Redux/HP";
export default function UploadSoftwareLogic(props: any) {
  const {
    mcxFileOnGateway,
    mcxFileUploadProgress,
    fileChunks,
    newSoftwareTopic,
    controllerIsSet,
    fileToUpload,
    fileChecksum,
    mcxGWRestarted,
  } = useSelector((state: any) => state.heatpump);
  const dispatch = useDispatch();
  const [disabledUploadBtn, setDisabledUploadBtn] = React.useState(false);

  const uploadFile = () => {
    setDisabledUploadBtn(true);
    //const uploadSteps = parseFloat(10 / fileChunks.length)
    fileChunks.forEach((element: any, index: number) => {
      var msg = {
        mcxUpdate: true,
        controllerID: controllerIsSet,
        filename: fileToUpload,
        chunknumber: index + 1,
        totalChunks: fileChunks.length,
        chunk: element,
        md5File: fileChecksum,
      };
      //console.log((new TextEncoder().encode(JSON.stringify(msg)).length)/ 1024)
      //console.log(msg)
      PubSub.publish(newSoftwareTopic, msg);
      //setUploadState(uploadSteps * index)
    });
    dispatch(setFileToUpload({}));
    dispatch(setFileChecksum(0));
    dispatch(setFileChunks([]));
  };

  return {
    mcxFileOnGateway,
    mcxFileUploadProgress,
    uploadFile,
    disabledUploadBtn,
    mcxGWRestarted,
  };
}
