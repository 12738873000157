import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Settings from "@mui/icons-material/Settings";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";

const ACTIONS = [
  {
    value: "remove",
    label: "Delete",
    icon: <DeleteIcon />,
  },
  {
    value: "manage",
    label: "Manage",
    icon: <Settings />,
  },
];

const buildColumns = ({ onActionClick }) => {
  const columns: GridColDef[] = [
    { field: "name", headerName: "Name", width: 400 },
    { field: "description", headerName: "Description", width: 400 },
    {
      field: "defaultActive",
      headerName: "Default Active",
      width: 200,
      type: "boolean",
    },
    {
      field: "createdAt",
      headerName: "Created At",
      type: "date",
      valueFormatter: ({ value }) => {
        return new Date(value).toLocaleString();
      },
      width: 300,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 150,
      cellClassName: "actions",
      sortable: false,
      renderCell: (row) => {
        return (
          <>
            <Box sx={{ p: 2 }}>
              {ACTIONS.map((action) => (
                <IconButton
                  key={action.value}
                  onClick={() => {
                    onActionClick(action.value, row);
                  }}
                  size="small"
                >
                  <Tooltip title={action.label} placement="top">
                    {action.icon}
                  </Tooltip>
                </IconButton>
              ))}
            </Box>
          </>
        );
      },
    },
  ];
  return columns;
};

export function Table({ data, error, isLoading, onActionClick }) {
  return (
    <DataGridPro
      rows={data || []}
      columns={buildColumns({
        onActionClick,
      })}
      loading={isLoading}
      getRowId={(row) => row.sk}
      error={error}
      initialState={{
        pinnedColumns: {
          left: ["role"],
          right: ["actions"],
        },
      }}
    />
  );
}
