import { useState, useEffect, useRef } from "react";
import DashboardView from "../Dashboard";
import HPcontrol from "../HPcontrol";
import UsersView from "../Users/Users";
import Gateways from "../Gateways";
import Software from "../Software";
import { MainViewDataHandler } from "../MainViewDataHandler";

export const MainViewLogic = () => {
  const { getData } = MainViewDataHandler();
  const [mainView, setMainview] = useState("Dashboard");
  const [headerTitle, setHeaderTitle] = useState("Dashboard");
  const [userData, setUserData] = useState([]);
  const [height, setHeight] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  const changeContent = (param: string) => {
    switch (param) {
      case "Dashboard":
        setMainview("Dashboard");
        setHeaderTitle("Dashboard");
        break;
      case "HPTable":
        getData();
        setMainview("HPTable");
        setHeaderTitle("Overview");
        break;
      case "Gateways":
        setMainview("Gateways");
        setHeaderTitle("Gateways");
        break;
      case "Software":
        setMainview("Software");
        setHeaderTitle("Software");
        break;
      case "Users":
        setMainview("Users");
        setHeaderTitle("Users");
        break;
      case "AccessManagement":
        setMainview("AccessManagement");
        setHeaderTitle("Access Management");
        break;
      case "RoleManagement":
        setMainview("RoleManagement");
        setHeaderTitle("Role Management");
        break;
      default:
        setMainview("Dashboard");
        setHeaderTitle("Dashboard");
        break;
    }
  };

  useEffect(() => {
    setHeight(((ref?.current?.clientHeight || 0) / 100) * 80);
  }, [ref]);

  const showContent = () => {
    switch (mainView) {
      case "Dashboard":
        return <DashboardView />;
      case "HPTable":
        return <HPcontrol height={height} />;
      case "Gateways":
        return <Gateways height={height} />;
      case "Software":
        return <Software height={height} />;
      case "Users":
        return (
          <UsersView rows={userData} setRows={setUserData} height={height} />
        );
      case "AccessManagement":
        return <></>;
      case "RoleManagement":
        return <></>;
      default:
        return <DashboardView />;
    }
  };

  return { headerTitle, changeContent, showContent, ref };
};
