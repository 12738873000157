import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import XAxisLogic from './XAxisLogic'

export default function XAxisView() {
    const {actualDate, xDuration, liveData, handleTimeChange, setXMinutes, changeDateFromPicker} = XAxisLogic()



  return (
    <Grid container spacing={2} justifyContent="space-between" alignItems="center" >
                <Grid item xs={1} md={1} lg={6}>
                    <IconButton color="primary" aria-label="backward" component="span" onClick={()=>handleTimeChange('timeBackward')}>
                        <ChevronLeftIcon />
                    </IconButton>
                </Grid>
               
                <Grid item xs={0} md={2} lg={2} sx={{ display: { xs: 'none', md: 'block', lg: 'block' } }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                        <DatePicker value={actualDate} onChange={changeDateFromPicker}   format="DD-MM-YYYY" slotProps={{ textField: { size: 'small' } }}/>
                    </LocalizationProvider>
                </Grid>
               
                <Grid item xs={10} md={6} lg={3}>
                    <Stack direction="row" spacing={0} alignItems="center">
                        <FormControl size="small" fullWidth>
                            <InputLabel id="demo-simple-select-label">Range</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={xDuration}
                                onChange={setXMinutes}
                                label="Range"
                            >
                                <MenuItem value={300000}>5m</MenuItem>
                                <MenuItem value={600000}>10m</MenuItem>
                                <MenuItem value={900000}>15m</MenuItem>
                                <MenuItem value={1800000}>30m</MenuItem>
                                <MenuItem value={3600000}>1h</MenuItem>
                                <MenuItem value={7200000}>2h</MenuItem>
                                <MenuItem value={10800000}>3h</MenuItem>
                                <MenuItem value={21600000}>6h</MenuItem>
                                <MenuItem value={43200000}>12h</MenuItem>
                                <MenuItem value={86400000}>24h</MenuItem>
                            </Select>
                        </FormControl>
                
                
                    
                        <IconButton color="primary" aria-label="forward" component="span" onClick={()=>handleTimeChange('timeForward')} disabled={liveData}>
                            <ChevronRightIcon />
                        </IconButton>
                        <IconButton color="primary" aria-label="forward" component="span" onClick={()=>handleTimeChange('timeLive')} disabled={liveData}>
                            <DoubleArrowIcon />
                        </IconButton>
                    </Stack>
                </Grid>
                
            </Grid>

  )
}
