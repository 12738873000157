import React from "react";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";

export default function ControlButtonsLogic(props: any) {
  const { fileToUpload, controllerIsSet } = useSelector(
    (state: any) => state.heatpump
  );

  const showNextButton = () => {
    //console.log(steps)
    let btnDisabled;
    if (props.activeStep === 0) {
      if (fileToUpload === "") {
        return (
          <Button disabled onClick={props.handleNext}>
            Next
          </Button>
        );
      } else {
        return <Button onClick={props.handleNext}>Next</Button>;
      }
    } else if (props.activeStep === 1) {
      if (controllerIsSet) {
        return <Button onClick={props.handleNext}>Next</Button>;
      } else {
        return (
          <Button disabled onClick={props.handleNext}>
            Next
          </Button>
        );
      }
    } else if (props.activeStep === 2) {
      return "";
    }

    return (
      <Button disabled={btnDisabled} onClick={props.handleNext}>
        {props.activeStep === props.steps.length - 1 ? "Upload" : "Next"}
      </Button>
    );
  };
  const showBackButton = () => {
    if (props.activeStep !== 0) {
      return (
        <Button
          color="primary"
          disabled={props.activeStep === 0}
          onClick={props.handleBack}
          sx={{ mr: 1 }}
        >
          Back
        </Button>
      );
    } else {
      return "";
    }
  };

  return { showBackButton, showNextButton };
}
