import LogOutputLogic from "./LogOutputLogic";

export default function LogOutputView() {
  const { renderOneLog, logs, sortObject } = LogOutputLogic();

  return (
    <div>
      {logs &&
        Object.keys(sortObject(logs)).map((Log) =>
          renderOneLog(Log, logs[Log])
        )}
    </div>
  );
}
