import Paper from '@mui/material/Paper';
import { styled } from "@mui/material/styles";

import { useSelector } from "react-redux";


export default function HPInformationsLogic() {
    const { HPData } = useSelector((state: any) => state.heatpump);


    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
        ...theme.typography.body2,
        padding: theme.spacing(1),
        color: theme.palette.text.secondary,
      }));

      
      const rows = [
        {name: 'Name', value: HPData['Name']},
        {name: 'Model', value: HPData['Model']},
        {name: 'Serialnumber', value: HPData['Serialnumber']},
        {name: 'GatewayID', value: HPData['GatewayID']},
        {name: 'Address', value: HPData['Address']},
        {name: 'City', value: HPData['City']},
        {name: 'Zipcode', value: HPData['Postcode']},
        {name: 'Contact', value: HPData['ContactPerson']},
        {name: 'Phone', value: HPData['ContactPersonPhone']},
      ];

      

  return {Item, rows}
}
