import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { AccessRightsForm } from "../../../AccessRightsForm";
import { useMemo, useState } from "react";
import { Box, Grid, Paper, Alert, Button } from "@mui/material";
import { API } from "aws-amplify";
import useSWR, { useSWRConfig } from "swr";
import SaveIcon from "@mui/icons-material/Save";
import CircularProgress from "@mui/material/CircularProgress";
import { useSnackbar } from "notistack";
import { useAuthorizer } from "../../../util/authorizer";

type Inputs = {
  rules: {
    // SoftwareModel -> SoftwareVersion -> Rule
    [key: string]: {
      [key: string]: {
        [key: string]: boolean;
      };
    };
  };
  permissions?: {
    [key: string]: boolean;
  };
};

const patchOrgSetRules = async (organization, rules, permissions) => {
  const newData = {
    id: organization.sk,
    rules,
    permissions,
  };
  const apiName = "ThermonovaAPI";
  const path = "/organization/update";
  await API.post(apiName, path, {
    body: newData,
  });
};

export function ManageAccessRights({ organization }) {
  const { enqueueSnackbar } = useSnackbar();
  const [busy, setBusy] = useState(false);
  const [error, setError] = useState(null);
  const { organizationId: currentOrganizationId } = useAuthorizer();
  const methods = useForm<Inputs>({
    defaultValues: useMemo(() => {
      return {
        rules: organization?.rules || [],
        permissions: organization?.permissions || {},
      };
    }, [organization?.rules, organization?.permissions]),
  });

  const { mutate } = useSWRConfig();

  const { data: organizations } = useSWR("organizations");

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const execute = async () => {
      setBusy(true);
      setError(null);
      try {
        const newRules = data.rules;
        const permissions = data.permissions;
        await patchOrgSetRules(organization, newRules, permissions);
        mutate("organizations");
        mutate(organization.sk);
        enqueueSnackbar("Access rights updated", {
          variant: "success",
        });
      } catch (error) {
        const errorText = error.message || error;
        setError(errorText);
        enqueueSnackbar("Error: " + errorText, {
          variant: "error",
        });
      } finally {
        setBusy(false);
      }
    };
    execute();
  };

  const currentOrganization = organizations?.row?.find(
    (org) => org.id === currentOrganizationId
  );
  const permissions = currentOrganization?.permissions || {};

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div>
          <Paper
            sx={{ width: "100%", overflow: "hidden", position: "relative" }}
          >
            <Box sx={{ height: "80vh", padding: 2 }}>
              <AccessRightsForm orgPermissions={permissions} />
              {error && (
                <Grid item xs={12} md={8}>
                  <Alert severity="error">{error}</Alert>
                </Grid>
              )}
              <Grid item xs={12} md={8}>
                <Button
                  type="submit"
                  variant="outlined"
                  size="large"
                  sx={{ mt: 2, float: "right", minWidth: "100px" }}
                  startIcon={
                    busy ? <CircularProgress size={20} /> : <SaveIcon />
                  }
                  disabled={busy}
                >
                  Save
                </Button>
              </Grid>
            </Box>
          </Paper>
        </div>
      </form>
    </FormProvider>
  );
}
