import MultilineTextField from "./LogInput";
import Grid from "@mui/material/Grid";
import LogViewLogic from "./LogViewLogic";
import LogOutputView from "./LogOutputView";

export default function LogView() {
  const { Item } = LogViewLogic();

  return (
    <Item id="SWUploadViewBoxLogsItemItem">
      <Grid container spacing={3} id="SWUploadViewBoxLogsItemItemContainer">
        <Grid item xs={12}>
          <div>
            <LogOutputView />
          </div>
        </Grid>
        <Grid item xs={12} id="SWUploadViewBoxLogsItemItemContainerItem">
          <MultilineTextField />
        </Grid>
      </Grid>
    </Item>
  );
}
