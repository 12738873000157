import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Email from "@mui/icons-material/Email";
import Business from "@mui/icons-material/Business";
import { useForm, SubmitHandler } from "react-hook-form";
import Box from "@mui/material/Box";
import { API } from "aws-amplify";
import { useSWRConfig } from "swr";
import ThermostatIcon from "@mui/icons-material/Thermostat";
import BusinessIcon from "@mui/icons-material/Business";
import GroupIcon from "@mui/icons-material/Group";

import Grid from "@mui/material/Grid";
import { Alert, List, ListItem, ListItemText, Typography } from "@mui/material";
import { StatItem, Item } from "../../../Shared/StatItem";

type Inputs = {
  name: string;
  administrator_email: string;
  description: string;
  contact_name: string;
  contact_email: string;
  website: string;
};

export function OverviewTab({ organization }) {
  return (
    <>
      <Grid
        xs={12}
        container
        justifyContent="space-between"
        flexDirection={{ xs: "column", sm: "row" }}
        sx={{ fontSize: "12px", mt: 2 }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={3}>
              <StatItem
                title="Heat pumps"
                value={
                  organization?.heatpumps
                    ? organization.heatpumps.length.toString()
                    : "0"
                }
                icon={ThermostatIcon}
                color="#394B53"
              />
            </Grid>
            <Grid item xs={12} md={12} lg={3}>
              <StatItem
                title="Customers"
                value={organization?.descendantCount?.toString() || "0"}
                color="#394B53"
                icon={BusinessIcon}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={3}>
              <StatItem
                title="Users"
                value={
                  organization?.users
                    ? organization.users.length.toString()
                    : "0"
                }
                color="#394B53"
                icon={GroupIcon}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={3}>
              <OverviewForm organization={organization} />
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </>
  );
}

function OverviewForm({ organization }) {
  const [busy, setBusy] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [editMode, setEditMode] = React.useState(false);
  const { mutate } = useSWRConfig();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      name: organization?.name,
      administrator_email: organization?.administrator,
      description: organization?.description,
      contact_name: organization?.contact_name,
      contact_email: organization?.contact_email,
      website: organization?.website,
    },
  });

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const execute = async () => {
      setBusy(true);
      setError(null);
      try {
        const apiName = "ThermonovaAPI";
        const path = "/organization/update";
        await API.post(apiName, path, {
          body: {
            id: organization.sk,
            name: data.name,
            description: data.description,
            contact_name: data.contact_name,
            contact_email: data.contact_email,
            website: data.website,
          },
        });
        mutate("organizations");
        mutate(organization.sk);
        setBusy(false);
        setEditMode(false);
      } catch (error) {
        const errorText = error.message || error;
        setError(errorText);
      }
    };
    execute();
  };

  const FormOrDescriptionList = () => {
    return (
      <>
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <List
              sx={{
                width: "100%",
                // minWidth: 460,
              }}
            >
              <ListItem>
                {editMode ? (
                  <TextField
                    autoFocus
                    required
                    margin="dense"
                    size="small"
                    id="name"
                    name="name"
                    fullWidth
                    label="Organization Name"
                    type="text"
                    defaultValue={organization?.name}
                    {...register("name")}
                    error={!!errors.name}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Business />
                        </InputAdornment>
                      ),
                    }}
                  />
                ) : (
                  <ListItemText primary="Name" secondary={organization?.name} />
                )}
              </ListItem>
              <ListItem>
                {editMode ? (
                  <TextField
                    autoFocus
                    required
                    margin="dense"
                    size="small"
                    id="amninistrator_email"
                    name="administrator_email"
                    label="Administrator Email"
                    type="email"
                    fullWidth
                    disabled
                    defaultValue={organization?.administrator}
                    InputProps={{
                      readOnly: true,
                      startAdornment: (
                        <InputAdornment position="start">
                          <Email />
                        </InputAdornment>
                      ),
                    }}
                  />
                ) : (
                  <ListItemText
                    primary="Administrator Email"
                    secondary={organization?.administrator}
                  />
                )}
              </ListItem>
              <ListItem>
                {editMode ? (
                  <TextField
                    autoFocus
                    id="description"
                    name="description"
                    label="Description"
                    placeholder="Description"
                    type="text"
                    multiline
                    margin="dense"
                    size="small"
                    rows={2}
                    fullWidth
                    error={!!errors.description}
                    {...register("description")}
                  />
                ) : (
                  <ListItemText
                    primary="Description"
                    secondary={organization?.description}
                  />
                )}
              </ListItem>
              <ListItem>
                {editMode ? (
                  <TextField
                    autoFocus
                    id="contact_name"
                    name="contact_name"
                    label="Contact Name"
                    type="text"
                    fullWidth
                    margin="dense"
                    size="small"
                    error={!!errors.contact_name}
                    {...register("contact_name")}
                  />
                ) : (
                  <ListItemText
                    primary="Contact Name"
                    secondary={organization?.contact_name}
                  />
                )}
              </ListItem>
              <ListItem>
                {editMode ? (
                  <TextField
                    autoFocus
                    id="contact_email"
                    name="contact_email"
                    label="Contact Email"
                    type="email"
                    fullWidth
                    margin="dense"
                    size="small"
                    error={!!errors.contact_email}
                    {...register("contact_email")}
                  />
                ) : (
                  <ListItemText
                    primary="Contact Email"
                    secondary={organization?.contact_email}
                  />
                )}
              </ListItem>
              <ListItem>
                {editMode ? (
                  <TextField
                    autoFocus
                    id="website"
                    name="website"
                    label="Website"
                    type="text"
                    margin="dense"
                    size="small"
                    fullWidth
                    error={!!errors.website}
                    {...register("website")}
                  />
                ) : (
                  <ListItemText
                    primary="Website"
                    secondary={organization?.website}
                  />
                )}
              </ListItem>
            </List>
            {editMode ? (
              <div>
                <Button
                  type="submit"
                  disabled={busy}
                  fullWidth
                  variant="contained"
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={() => setEditMode(false)}
                  style={{ marginTop: 8 }}
                >
                  Cancel
                </Button>
              </div>
            ) : (
              <Button
                variant="outlined"
                onClick={() => setEditMode(true)}
                fullWidth
              >
                Edit
              </Button>
            )}
          </form>
          {error && <Alert severity="error">{error}</Alert>}
        </div>
      </>
    );
  };

  return (
    <Item>
      <Typography variant="h6" component="div">
        Organization Information
      </Typography>
      <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
        <FormOrDescriptionList />
      </Box>
    </Item>
  );
}
