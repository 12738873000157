import React from "react";
import { useSelector } from "react-redux";
import ThermostatIcon from "@mui/icons-material/Thermostat";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import WifiIcon from "@mui/icons-material/Wifi";
import { StatItem } from "../Shared/StatItem";

export const DashboardLogic = () => {
  const heatpumps = useSelector((state: any) => state.mainData.heatpumps);
  const [hpWithError, setHpWithError] = React.useState([]);
  const [hpOnline, setHpOnline] = React.useState([]);

  React.useEffect(() => {
    var listErrorHP = [];
    var listHPOnline = [];
    for (const property in heatpumps) {
      if (heatpumps[property].alarm === "true") {
        listErrorHP.push(heatpumps[property]);
      }

      var ts = Date.now() - 900000;
      var onlineTs = Date.parse(heatpumps[property].col13);
      if (onlineTs >= ts) {
        listHPOnline.push(heatpumps[property]);
      }
    }
    setHpWithError(listErrorHP);
    setHpOnline(listHPOnline);
  }, [heatpumps]);

  const viewNumberofheatpumps = (
    <StatItem
      icon={ThermostatIcon}
      title="Heat pumps"
      value={Object.keys(heatpumps).length}
      color="#EF6416"
    />
  );

  const viewNumberofheatpumpsOnline = (
    <StatItem
      icon={WifiIcon}
      title="Heat pumps online"
      value={Object.keys(hpOnline).length}
      color="#3E98B6"
    />
  );

  const viewNumberofheatpumpsWithError = (
    <StatItem
      icon={ErrorOutlineIcon}
      title="Heat pumps with problem"
      value={hpWithError.length}
      color="#FBB935"
    />
  );

  return {
    viewNumberofheatpumps,
    viewNumberofheatpumpsWithError,
    viewNumberofheatpumpsOnline,
  };
};
