import React from "react";
import Grid from "@mui/material/Grid";
import { API } from "aws-amplify";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";

// import { useAppSelector, useAppDispatch } from "../../../hooks/redux";
import { useSelector, useDispatch } from "react-redux";
import { setLogs } from "../Redux/HP";

export default function LogOutputLogic() {
  const { HPData, logs } = useSelector((state: any) => state.heatpump);
  const dispatch = useDispatch();

  async function APIdeleteLog(serialnumber: string, timestamp: string) {
    const apiName = "ThermonovaAPI";
    const path = "/HP/logs/log";
    const myInit = {
      // OPTIONAL
      body: {
        Serialnumber: serialnumber,
        LogTimestamp: timestamp,
      }, // replace this with attributes you need
    };

    return await API.del(apiName, path, myInit);
  }
  async function APIgetLogs(serialnumber: string) {
    const apiName = "ThermonovaAPI";
    const path = "/HP/logs";
    const myInit = {
      // OPTIONAL
      queryStringParameters: {
        Serialnumber: serialnumber,
      }, // replace this with attributes you need
    };

    return await API.get(apiName, path, myInit);
  }

  const handleDeleteLog = (TimeStamp: string) => {
    //console.log(TimeStamp)
    APIdeleteLog(HPData.Serialnumber, TimeStamp).then((response) => {
      //trigger reload
      getLogs();
    });
  };

  const getLogs = () => {
    APIgetLogs(HPData.Serialnumber).then((response) => {
      dispatch(setLogs(response));
    });
  };
  function sortObject(obj: any) {
    return Object.keys(obj)
      .sort()
      .reduce(function (result: any, key: string) {
        result[key] = obj[key];
        return result;
      }, {});
  }

  const renderOneLog = (Timestamp: string, data: any) => {
    return (
      <Card
        sx={{ minWidth: 275 }}
        key={Timestamp}
        variant="outlined"
        style={{ margin: "5px" }}
      >
        <CardContent>
          <Grid container spacing={0} justifyContent="space-between">
            <Grid item xs={6} style={{ textAlign: "start" }}>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
                align="left"
                style={{ display: "inline-block" }}
              >
                {new Date(Timestamp).toLocaleString()} - {data.User}
              </Typography>
            </Grid>

            <Grid item xs={6} style={{ textAlign: "end" }}>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
                align="right"
                style={{ display: "inline-block" }}
              >
                {/*
                                        <Tooltip title="Change">
                                            <IconButton aria-label="delete" size="small" onClick={(e) =>handleChangeLog(Timestamp, data)}>
                                                <CreateIcon fontSize="inherit" />
                                            </IconButton>
                                        </Tooltip>
                                        */}
                <Tooltip title="Delete">
                  <IconButton
                    aria-label="delete"
                    size="small"
                    onClick={(e) => handleDeleteLog(Timestamp)}
                  >
                    <DeleteIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              </Typography>
            </Grid>
          </Grid>

          <Typography variant="body2" align="left">
            {data.Msg}
          </Typography>
        </CardContent>
      </Card>
    );
  };

  return { renderOneLog, logs, sortObject };
}
