import React from "react";
import Box from "@mui/material/Box";
import ControlButtonsLogic from "./ControlButtonsLogic";

export default function ControlButtonsView(props: any) {
  const { showBackButton, showNextButton } = ControlButtonsLogic(props);
  return (
    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
      {showBackButton()}
      <Box sx={{ flex: "1 1 auto" }} />

      {showNextButton()}
    </Box>
  );
}
