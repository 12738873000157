import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import PersonIcon from "@mui/icons-material/Person";
import { blue } from "@mui/material/colors";
import { DialogContent, ListItemIcon } from "@mui/material";
import useSWR from "swr";
import { API } from "aws-amplify";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useEffect, useState } from "react";

export interface RoleDialogProps {
  user;
  onClose: () => void;
  onSelect: (role) => void;
}

const fetcher = () => {
  const apiName = "ThermonovaAPI";
  const path = "/role/list/user";
  return API.get(apiName, path, {});
};

export function RoleModal(props: RoleDialogProps) {
  const [busy, setBusy] = useState(false);
  const [checked, setChecked] = useState("");
  const [errorSubmitting, setErrorSubmitting] = useState(false);
  const { data, error, isLoading } = useSWR("roles", fetcher);

  useEffect(() => {
    if (props.user.Role) {
      setChecked(props.user.Role);
    }
  }, [props.user]);

  const handleClose = () => {};
  const handleRoleOnClick = (roleId) => {
    const performRoleUpdate = async () => {
      try {
        setBusy(true);
        const apiName = "ThermonovaAPI";
        const path = "/user/role";
        const myInit = {
          body: {
            id: props.user.id,
            roleId,
          },
        };
        await API.post(apiName, path, myInit);
        props.onSelect(roleId);
        props.onClose();
      } catch (error) {
        console.log(error);
        setErrorSubmitting(true);
      } finally {
        setBusy(false);
      }
    };

    performRoleUpdate();
  };

  return (
    <div>
      <Dialog onClose={handleClose} open>
        <DialogTitle>User Role</DialogTitle>
        <DialogContent
          style={{
            minWidth: "350px",
          }}
        >
          {isLoading && <div>Loading...</div>}
          {error && <div>Failed to load</div>}
          <List sx={{ pt: 0 }}>
            {data?.map((role) => (
              <ListItem disableGutters key={role.sk}>
                <ListItemButton
                  onClick={() => {
                    setChecked(role.sk);
                  }}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={checked === role.sk}
                      tabIndex={-1}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                      <PersonIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={role.name} />
                </ListItemButton>
              </ListItem>
            ))}
            {errorSubmitting && (
              <Box sx={{ color: "error.main" }}>Error submitting</Box>
            )}
            <Box sx={{ display: "flex", justifyContent: "flex-end", p: 2 }}>
              <Button
                variant="contained"
                color="info"
                onClick={props.onClose}
                disabled={busy}
                style={{ marginRight: "10px" }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  handleRoleOnClick(checked);
                }}
                disabled={busy}
              >
                Save
              </Button>
            </Box>
          </List>
        </DialogContent>
      </Dialog>
    </div>
  );
}
